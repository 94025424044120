import {Alignment, Classes, Label, Switch} from '@blueprintjs/core';
import React, {memo} from 'react';
import {stylesheet} from 'typestyle';
import {Schema} from './proto/deeplay/category_store/v2/category_store';
import {Attributes} from './types';
import {ValueInput} from './ValueInput';

export type GetQueryEditorProps = {
  schema: Schema;
  value: Attributes;
  onChange(value: Attributes): void;
};

const styles = stylesheet({
  root: {},

  label: {
    marginBottom: '5px !important',
  },

  attribute: {
    marginBottom: 15,
  },
});

export const GetQueryEditor: React.FC<GetQueryEditorProps> = memo(props => {
  const {schema, value, onChange} = props;

  return (
    <div className={styles.root}>
      {schema.attributes.map((attribute, i) => (
        <div key={i} className={styles.attribute}>
          {attribute.isImplicit ? (
            <Switch
              className={styles.label}
              alignIndicator={Alignment.RIGHT}
              labelElement={
                <>
                  {attribute.key}{' '}
                  {attribute.isImplicit && (
                    <span className={Classes.TEXT_MUTED}>(implicit)</span>
                  )}
                </>
              }
              checked={value.has(attribute.key)}
              onChange={e => {
                const nextValue = new Map(value);

                if (e.currentTarget.checked) {
                  nextValue.set(attribute.key, '');
                } else {
                  nextValue.delete(attribute.key);
                }

                onChange(nextValue);
              }}
            />
          ) : (
            <Label className={styles.label}>{attribute.key}</Label>
          )}
          <ValueInput
            validator={attribute.validator}
            disabled={!value.has(attribute.key)}
            value={value.get(attribute.key) ?? ''}
            onChange={attributeValue => {
              const nextValue = new Map(value);

              nextValue.set(attribute.key, attributeValue);

              onChange(nextValue);
            }}
          />
        </div>
      ))}
    </div>
  );
});
