import {Tabs, Tab} from '@blueprintjs/core';
import React from 'react';
import {useClient} from './AuthContext';
import {GetQueryPanel} from './GetQueryPanel';
import {ListQueryPanel} from './ListQueryPanel';
import {hasPermission} from './permissions';
import {Schema} from './proto/deeplay/category_store/v2/category_store';
import {RulesPanel} from './RulesPanel';
import {SchemaPanel} from './SchemaPanel';
import {SetQueryPanel} from './SetQueryPanel';

export type CategorizationViewProps = {
  categorization: string;
  schema: Schema;
  onSave(entry: {categorization: string; schema: Schema}): void;
  onDelete(categorization: string): void;
};

export const CategorizationView: React.FC<CategorizationViewProps> = props => {
  const {categorization, schema, onSave, onDelete} = props;

  const client = useClient();

  return (
    <Tabs id="categorization-view-tabs" large>
      <Tab
        id="schema"
        title="Schema"
        panel={
          <SchemaPanel
            categorization={categorization}
            schema={schema}
            onSave={onSave}
            onDelete={onDelete}
          />
        }
      />
      <Tab
        id="get"
        title="Get"
        disabled={!hasPermission(client, categorization, 'read')}
        panel={
          <GetQueryPanel categorization={categorization} schema={schema} />
        }
      />
      <Tab
        id="list"
        title="List"
        disabled={!hasPermission(client, categorization, 'read')}
        panel={
          <ListQueryPanel categorization={categorization} schema={schema} />
        }
      />
      <Tab
        id="set"
        title="Set"
        disabled={!hasPermission(client, categorization, 'write')}
        panel={
          <SetQueryPanel categorization={categorization} schema={schema} />
        }
      />
      <Tab
        id="rules"
        title="Rules"
        panel={<RulesPanel categorization={categorization} schema={schema} />}
      />
    </Tabs>
  );
};
