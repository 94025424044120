/* eslint-disable */
import { messageTypeRegistry } from "../../../../typeRegistry";
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "deeplay.category_store.access_control.v2";

export interface Permission {
  $type: "deeplay.category_store.access_control.v2.Permission";
  categorizationPattern: string;
  read: boolean;
  write: boolean;
  manage: boolean;
}

export interface Client {
  $type: "deeplay.category_store.access_control.v2.Client";
  clientId: string;
  permissions: Permission[];
}

export interface GetClientsRequest {
  $type: "deeplay.category_store.access_control.v2.GetClientsRequest";
}

export interface GetClientsResponse {
  $type: "deeplay.category_store.access_control.v2.GetClientsResponse";
  entries: GetClientsResponse_ClientEntry[];
}

export interface GetClientsResponse_ClientEntry {
  $type: "deeplay.category_store.access_control.v2.GetClientsResponse.ClientEntry";
  token: string;
  client: Client | undefined;
}

export interface SetClientRequest {
  $type: "deeplay.category_store.access_control.v2.SetClientRequest";
  token: string;
  client: Client | undefined;
}

export interface SetClientResponse {
  $type: "deeplay.category_store.access_control.v2.SetClientResponse";
}

const basePermission: object = {
  $type: "deeplay.category_store.access_control.v2.Permission",
  categorizationPattern: "",
  read: false,
  write: false,
  manage: false,
};

export const Permission = {
  $type: "deeplay.category_store.access_control.v2.Permission" as const,

  encode(
    message: Permission,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.categorizationPattern !== "") {
      writer.uint32(10).string(message.categorizationPattern);
    }
    if (message.read === true) {
      writer.uint32(16).bool(message.read);
    }
    if (message.write === true) {
      writer.uint32(24).bool(message.write);
    }
    if (message.manage === true) {
      writer.uint32(32).bool(message.manage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Permission {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...basePermission } as Permission;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categorizationPattern = reader.string();
          break;
        case 2:
          message.read = reader.bool();
          break;
        case 3:
          message.write = reader.bool();
          break;
        case 4:
          message.manage = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Permission>): Permission {
    const message = { ...basePermission } as Permission;
    if (
      object.categorizationPattern !== undefined &&
      object.categorizationPattern !== null
    ) {
      message.categorizationPattern = object.categorizationPattern;
    }
    if (object.read !== undefined && object.read !== null) {
      message.read = object.read;
    }
    if (object.write !== undefined && object.write !== null) {
      message.write = object.write;
    }
    if (object.manage !== undefined && object.manage !== null) {
      message.manage = object.manage;
    }
    return message;
  },
};

messageTypeRegistry.set(Permission.$type, Permission);

const baseClient: object = {
  $type: "deeplay.category_store.access_control.v2.Client",
  clientId: "",
};

export const Client = {
  $type: "deeplay.category_store.access_control.v2.Client" as const,

  encode(
    message: Client,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    for (const v of message.permissions) {
      Permission.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Client {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseClient } as Client;
    message.permissions = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clientId = reader.string();
          break;
        case 2:
          message.permissions.push(Permission.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Client>): Client {
    const message = { ...baseClient } as Client;
    message.permissions = [];
    if (object.clientId !== undefined && object.clientId !== null) {
      message.clientId = object.clientId;
    }
    if (object.permissions !== undefined && object.permissions !== null) {
      for (const e of object.permissions) {
        message.permissions.push(Permission.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(Client.$type, Client);

const baseGetClientsRequest: object = {
  $type: "deeplay.category_store.access_control.v2.GetClientsRequest",
};

export const GetClientsRequest = {
  $type: "deeplay.category_store.access_control.v2.GetClientsRequest" as const,

  encode(
    _: GetClientsRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetClientsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetClientsRequest } as GetClientsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<GetClientsRequest>): GetClientsRequest {
    const message = { ...baseGetClientsRequest } as GetClientsRequest;
    return message;
  },
};

messageTypeRegistry.set(GetClientsRequest.$type, GetClientsRequest);

const baseGetClientsResponse: object = {
  $type: "deeplay.category_store.access_control.v2.GetClientsResponse",
};

export const GetClientsResponse = {
  $type: "deeplay.category_store.access_control.v2.GetClientsResponse" as const,

  encode(
    message: GetClientsResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.entries) {
      GetClientsResponse_ClientEntry.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetClientsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetClientsResponse } as GetClientsResponse;
    message.entries = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.entries.push(
            GetClientsResponse_ClientEntry.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetClientsResponse>): GetClientsResponse {
    const message = { ...baseGetClientsResponse } as GetClientsResponse;
    message.entries = [];
    if (object.entries !== undefined && object.entries !== null) {
      for (const e of object.entries) {
        message.entries.push(GetClientsResponse_ClientEntry.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(GetClientsResponse.$type, GetClientsResponse);

const baseGetClientsResponse_ClientEntry: object = {
  $type:
    "deeplay.category_store.access_control.v2.GetClientsResponse.ClientEntry",
  token: "",
};

export const GetClientsResponse_ClientEntry = {
  $type:
    "deeplay.category_store.access_control.v2.GetClientsResponse.ClientEntry" as const,

  encode(
    message: GetClientsResponse_ClientEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.client !== undefined) {
      Client.encode(message.client, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetClientsResponse_ClientEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseGetClientsResponse_ClientEntry,
    } as GetClientsResponse_ClientEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        case 2:
          message.client = Client.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetClientsResponse_ClientEntry>
  ): GetClientsResponse_ClientEntry {
    const message = {
      ...baseGetClientsResponse_ClientEntry,
    } as GetClientsResponse_ClientEntry;
    if (object.token !== undefined && object.token !== null) {
      message.token = object.token;
    }
    if (object.client !== undefined && object.client !== null) {
      message.client = Client.fromPartial(object.client);
    }
    return message;
  },
};

messageTypeRegistry.set(
  GetClientsResponse_ClientEntry.$type,
  GetClientsResponse_ClientEntry
);

const baseSetClientRequest: object = {
  $type: "deeplay.category_store.access_control.v2.SetClientRequest",
  token: "",
};

export const SetClientRequest = {
  $type: "deeplay.category_store.access_control.v2.SetClientRequest" as const,

  encode(
    message: SetClientRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.client !== undefined) {
      Client.encode(message.client, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetClientRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetClientRequest } as SetClientRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.token = reader.string();
          break;
        case 2:
          message.client = Client.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SetClientRequest>): SetClientRequest {
    const message = { ...baseSetClientRequest } as SetClientRequest;
    if (object.token !== undefined && object.token !== null) {
      message.token = object.token;
    }
    if (object.client !== undefined && object.client !== null) {
      message.client = Client.fromPartial(object.client);
    }
    return message;
  },
};

messageTypeRegistry.set(SetClientRequest.$type, SetClientRequest);

const baseSetClientResponse: object = {
  $type: "deeplay.category_store.access_control.v2.SetClientResponse",
};

export const SetClientResponse = {
  $type: "deeplay.category_store.access_control.v2.SetClientResponse" as const,

  encode(
    _: SetClientResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetClientResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetClientResponse } as SetClientResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<SetClientResponse>): SetClientResponse {
    const message = { ...baseSetClientResponse } as SetClientResponse;
    return message;
  },
};

messageTypeRegistry.set(SetClientResponse.$type, SetClientResponse);

/**
 * Все запросы должны быть подписаны токеном, т.е. иметь хедер
 *
 *   Authorization: Bearer <token>
 */
export const AccessControlDefinition = {
  name: "AccessControl",
  fullName: "deeplay.category_store.access_control.v2.AccessControl",
  methods: {
    getClients: {
      name: "GetClients",
      requestType: GetClientsRequest,
      requestStream: false,
      responseType: GetClientsResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    setClient: {
      name: "SetClient",
      requestType: SetClientRequest,
      requestStream: false,
      responseType: SetClientResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
  },
} as const;

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & {
      $case: T["$case"];
    }
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
