import {rethrowAbortError, throwIfAborted} from 'abort-controller-x';
import {useState} from 'react';
import {useAbortableEffect} from './useAbortableEffect';

export type QueryState<T> =
  | {
      status: 'loading';
    }
  | {
      status: 'error';
      error: unknown;
    }
  | {
      status: 'done';
      result: T;
    };

export function useQuery<T>(
  fn: (signal: AbortSignal) => Promise<T>,
  deps: unknown[],
): QueryState<T> {
  const [state, setState] = useState<QueryState<T>>({status: 'loading'});

  /* eslint-disable react-hooks/exhaustive-deps */

  useAbortableEffect(async signal => {
    setState({status: 'loading'});

    try {
      const result = await fn(signal);

      throwIfAborted(signal);

      setState({status: 'done', result});
    } catch (error) {
      rethrowAbortError(error);
      setState({status: 'error', error});
    }
  }, deps);

  return state;
}
