import {Label} from '@blueprintjs/core';
import React, {memo} from 'react';
import {stylesheet} from 'typestyle';
import {Schema} from './proto/deeplay/category_store/v2/category_store';
import {SelectorInput} from './SelectorInput';
import {AttributeSelectors} from './types';
import {emptyArray} from './utils/empty';

export type ListQueryEditorProps = {
  schema: Schema;
  value: AttributeSelectors;
  onChange(value: AttributeSelectors): void;
};

const styles = stylesheet({
  root: {},

  label: {
    marginBottom: '5px !important',
  },

  attribute: {
    marginBottom: 15,
  },
});

export const ListQueryEditor: React.FC<ListQueryEditorProps> = memo(props => {
  const {schema, value, onChange} = props;

  return (
    <div className={styles.root}>
      {schema.attributes.map((attribute, i) => (
        <div key={i} className={styles.attribute}>
          <Label className={styles.label}>{attribute.key}</Label>
          <SelectorInput
            validator={attribute.validator}
            disabled={!value.has(attribute.key)}
            value={value.get(attribute.key) ?? emptyArray}
            onChange={valueIn => {
              const nextValue = new Map(value);

              nextValue.set(attribute.key, valueIn);

              onChange(nextValue);
            }}
          />
        </div>
      ))}
    </div>
  );
});
