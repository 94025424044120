import {FocusStyleManager} from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import 'normalize.css/normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {Auth} from './Auth';
import reportWebVitals from './reportWebVitals';

FocusStyleManager.onlyShowFocusOnTabs();

ReactDOM.render(
  // TODO: uncomment strict mode after
  // https://github.com/palantir/blueprint/issues/3979
  //
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <Auth />,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
