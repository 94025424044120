import {
  Button,
  Colors,
  Menu,
  MenuDivider,
  MenuItem,
  Spinner,
} from '@blueprintjs/core';
import {sortBy, sortedIndexBy} from 'lodash-es';
import React, {useState} from 'react';
import {insertAt, setAt} from 'typescript-immutable-utils';
import {stylesheet} from 'typestyle';
import {
  useAccessControlClient,
  useCategoryStoreClient,
  useClient,
} from './AuthContext';
import {CategorizationForm} from './CategorizationForm';
import {CategorizationName} from './CategorizationName';
import {CategorizationView} from './CategorizationView';
import {ClientForm} from './ClientForm';
import {ClientView} from './ClientView';
import {Client} from './proto/deeplay/category_store/access_control/v2/access_control';
import {Schema} from './proto/deeplay/category_store/v2/category_store';
import {Delay} from './utils/Delay';
import {invoke} from './utils/invoke';
import {useAbortableEffect} from './utils/useAbortableEffect';

export type AppProps = {
  onSignOutClick(): void;
};

const styles = stylesheet({
  root: {
    height: '100vh',
    display: 'flex',
  },

  main: {
    backgroundColor: Colors.LIGHT_GRAY5,
    flex: '0 0 100%',
    display: 'flex',
  },

  rightMargin: {
    backgroundColor: Colors.LIGHT_GRAY5,
    flex: '1 1 0',
  },

  sidebar: {
    backgroundColor: Colors.WHITE,
    flex: '0 0 250px',
    overflowX: 'hidden',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: Colors.GRAY5,
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    marginLeft: 2,
  },

  subtitle: {
    marginLeft: 4,
    marginBottom: 0,
  },

  sidebarSpacer: {
    flex: '1 1 0',
  },

  signOutButton: {
    marginBottom: 10,
  },

  content: {
    flex: '1 1 0',
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
    overflowY: 'auto',
  },
});

export const App: React.FC<AppProps> = props => {
  const {onSignOutClick} = props;

  const client = useClient();
  const categoryStoreClient = useCategoryStoreClient();
  const accessControlClient = useAccessControlClient();

  const [schemaEntries, setSchemaEntries] = useState<
    Array<{
      categorization: string;
      schema: Schema;
    }>
  >();

  useAbortableEffect(async signal => {
    const response = await categoryStoreClient.getSchemas({}, {signal});

    const entries: Array<{
      categorization: string;
      schema: Schema;
    }> = [];

    for (const {categorization, schema} of response.entries) {
      if (schema != null) {
        entries.push({categorization, schema});
      }
    }

    setSchemaEntries(entries);
  }, []);

  const [clientEntries, setClientEntries] = useState<
    Array<{
      token: string;
      client: Client;
    }>
  >();

  useAbortableEffect(
    async signal => {
      if (client.clientId !== 'admin') {
        return;
      }

      const response = await accessControlClient.getClients({}, {signal});

      const entries: Array<{
        token: string;
        client: Client;
      }> = [];

      for (const {token, client} of response.entries) {
        if (client != null) {
          entries.push({token, client});
        }
      }

      setClientEntries(sortBy(entries, entry => entry.client.clientId));
    },
    [client],
  );

  const [selectedMenuItem, setSelectedMenuItem] = useState<
    | {type: 'categorization'; categorization: string}
    | {type: 'new-categorization'}
    | {type: 'client'; token: string}
    | {type: 'new-client'}
  >();

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <div className={styles.sidebar}>
          <h1 className={styles.title}>Category Store</h1>

          <Menu>
            <MenuDivider title={<span>Categorizations </span>} />
            {schemaEntries == null ? (
              <Delay>
                <Spinner />
              </Delay>
            ) : (
              schemaEntries.map(({categorization}) => (
                <MenuItem
                  key={categorization}
                  text={<CategorizationName categorization={categorization} />}
                  active={
                    selectedMenuItem?.type === 'categorization' &&
                    selectedMenuItem.categorization === categorization
                  }
                  onClick={() => {
                    setSelectedMenuItem({
                      type: 'categorization',
                      categorization,
                    });
                  }}
                />
              ))
            )}
            {client.permissions.some(permission => permission.manage) && (
              <MenuItem
                icon="plus"
                text="Create categorization"
                active={selectedMenuItem?.type === 'new-categorization'}
                onClick={() => {
                  setSelectedMenuItem({type: 'new-categorization'});
                }}
              />
            )}

            {client.clientId === 'admin' && (
              <>
                <MenuDivider title="Clients" />
                {clientEntries == null ? (
                  <Delay>
                    <Spinner />
                  </Delay>
                ) : (
                  clientEntries.map(
                    ({client, token}) =>
                      client.clientId !== 'admin' && (
                        <MenuItem
                          key={token}
                          text={client.clientId}
                          active={
                            selectedMenuItem?.type === 'client' &&
                            selectedMenuItem.token === token
                          }
                          onClick={() => {
                            setSelectedMenuItem({
                              type: 'client',
                              token,
                            });
                          }}
                        />
                      ),
                  )
                )}
                <MenuItem
                  icon="plus"
                  text="Create client"
                  active={selectedMenuItem?.type === 'new-client'}
                  onClick={() => {
                    setSelectedMenuItem({type: 'new-client'});
                  }}
                />
              </>
            )}
          </Menu>

          <div className={styles.sidebarSpacer} />

          <Button
            className={styles.signOutButton}
            minimal
            fill
            text="Sign out"
            onClick={onSignOutClick}
          />
        </div>

        <div className={styles.content}>
          {invoke(() => {
            if (selectedMenuItem?.type === 'new-categorization') {
              return (
                <CategorizationForm
                  onCreate={entry => {
                    setSchemaEntries(entries => {
                      if (entries == null) {
                        return entries;
                      }

                      const index = sortedIndexBy(
                        entries,
                        entry,
                        entry => entry.categorization,
                      );

                      return entries[index]?.categorization ===
                        entry.categorization
                        ? setAt(entries, index, entry)
                        : insertAt(entries, index, entry);
                    });

                    setSelectedMenuItem({
                      type: 'categorization',
                      categorization: entry.categorization,
                    });
                  }}
                />
              );
            }

            if (selectedMenuItem?.type === 'categorization') {
              const selectedSchema = schemaEntries?.find(
                entry =>
                  entry.categorization === selectedMenuItem.categorization,
              )?.schema;

              return (
                selectedSchema != null && (
                  <CategorizationView
                    key={selectedMenuItem.categorization}
                    categorization={selectedMenuItem.categorization}
                    schema={selectedSchema}
                    onSave={({categorization, schema}) => {
                      setSchemaEntries(entries => {
                        if (entries == null) {
                          return;
                        }

                        const index = entries.findIndex(
                          entry => entry.categorization === categorization,
                        );

                        return setAt(entries, index, {
                          categorization,
                          schema,
                        });
                      });
                    }}
                    onDelete={categorization => {
                      setSchemaEntries(entries =>
                        entries?.filter(
                          entry => entry.categorization !== categorization,
                        ),
                      );
                      setSelectedMenuItem(undefined);
                    }}
                  />
                )
              );
            }

            if (selectedMenuItem?.type === 'new-client') {
              return (
                <ClientForm
                  onCreate={entry => {
                    setClientEntries(entries => {
                      if (entries == null) {
                        return entries;
                      }

                      const index = sortedIndexBy(
                        entries,
                        entry,
                        entry => entry.client.clientId,
                      );

                      return entries[index]?.token === entry.token
                        ? setAt(entries, index, entry)
                        : insertAt(entries, index, entry);
                    });

                    setSelectedMenuItem({
                      type: 'client',
                      token: entry.token,
                    });
                  }}
                />
              );
            }

            if (selectedMenuItem?.type === 'client') {
              const selectedClient = clientEntries?.find(
                entry => entry.token === selectedMenuItem.token,
              )?.client;

              return (
                selectedClient != null && (
                  <ClientView
                    key={selectedMenuItem.token}
                    token={selectedMenuItem.token}
                    client={selectedClient}
                    onSave={({token, client}) => {
                      setClientEntries(entries => {
                        if (entries == null) {
                          return;
                        }

                        const index = entries.findIndex(
                          entry => entry.token === token,
                        );

                        return setAt(entries, index, {
                          token,
                          client,
                        });
                      });
                    }}
                    onDelete={token => {
                      setClientEntries(entries =>
                        entries?.filter(entry => entry.token !== token),
                      );
                      setSelectedMenuItem(undefined);
                    }}
                  />
                )
              );
            }
          })}
        </div>
      </div>
      <div className={styles.rightMargin} />
    </div>
  );
};
