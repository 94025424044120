import {
  Button,
  ButtonGroup,
  ControlGroup,
  InputGroup,
  UL,
} from '@blueprintjs/core';
import React, {useState} from 'react';
import {removeAt, setAt} from 'typescript-immutable-utils';
import {stylesheet} from 'typestyle';
import {DescriptionInput} from './DescriptionInput';
import {Schema_Validator} from './proto/deeplay/category_store/v2/category_store';

export type ValidatorValues = {
  type: 'none' | 'regexp' | 'enum' | 'jsonschema';
  regexp: string;
  jsonschema: string;
  enum: Array<{
    value: string;
    description: string;
  }>;
};

const validatorNameByType = (type: ValidatorValues['type']): string => {
  switch (type) {
    case 'regexp':
      return 'RegExp';
    case 'enum':
      return 'Enum';
    case 'jsonschema':
      return 'JsonSchema';
    default:
      return 'None';
  }
};

export function createValidatorValues(
  validator: Schema_Validator | undefined,
): ValidatorValues {
  switch (validator?.validator?.$case) {
    case 'regexp':
      return {
        type: 'regexp',
        enum: [],
        jsonschema: '',
        regexp: validator.validator.regexp,
      };
    case 'enum':
      return {
        type: 'enum',
        enum: validator.validator.enum.elements,
        jsonschema: '',
        regexp: '',
      };
    case 'jsonschema':
      return {
        type: 'jsonschema',
        enum: [],
        jsonschema: validator.validator.jsonschema,
        regexp: '',
      };
    default:
      return {
        type: 'none',
        enum: [],
        jsonschema: '',
        regexp: '',
      };
  }
}

const styles = stylesheet({
  typeSection: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },

  paramsSection: {
    marginTop: 5,
    marginBottom: 5,
  },

  enumValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  enumRemoveButton: {
    height: 21,
    minHeight: 'auto',
  },

  enumNoElements: {
    padding: 5,
  },
});

export type ValidatorInputProps = {
  editable?: boolean;
  validator: ValidatorValues;
  onChange(value: ValidatorValues): void;
};

export const ValidatorInput: React.FC<ValidatorInputProps> = props => {
  const {editable, validator, onChange} = props;

  const [isEditing, setIsEditing] = useState(false);

  const [newEnumValue, setNewEnumValue] = useState('');

  const ResultButton = ({type}: {type: ValidatorValues['type']}) => (
    <Button
      minimal
      small
      text={validatorNameByType(type)}
      active={validator.type === type}
      onClick={() => {
        onChange({
          ...validator,
          type,
        });
      }}
    />
  );
  return (
    <div>
      <div className={styles.typeSection}>
        <div>
          <strong>Validator</strong>:{' '}
          {isEditing ? (
            <ButtonGroup>
              <ResultButton type="none" />
              <ResultButton type="enum" />
              <ResultButton type="regexp" />
              <ResultButton type="jsonschema" />
            </ButtonGroup>
          ) : (
            <span>{validatorNameByType(validator.type)}</span>
          )}
        </div>
        {editable && !isEditing && (
          <Button
            minimal
            small
            intent="primary"
            text="Change"
            onClick={() => {
              setIsEditing(true);
            }}
          />
        )}
      </div>
      {validator.type === 'regexp' && (
        <div className={styles.paramsSection}>
          {isEditing ? (
            <InputGroup
              placeholder="RegExp"
              value={validator.regexp}
              onChange={e => {
                const regexp = e.currentTarget.value;
                onChange({
                  ...validator,
                  regexp,
                });
              }}
            />
          ) : (
            <code>{validator.regexp}</code>
          )}
        </div>
      )}
      {validator.type === 'jsonschema' && (
        <div className={styles.paramsSection}>
          {isEditing ? (
            <InputGroup
              placeholder="JsonSchema"
              value={validator.jsonschema}
              onChange={e => {
                const jsonschema = e.currentTarget.value;
                onChange({...validator, jsonschema});
              }}
            />
          ) : (
            <code>{validator.jsonschema}</code>
          )}
        </div>
      )}
      {validator.type === 'enum' && (
        <div className={styles.paramsSection}>
          {validator.enum.length === 0 && (
            <div className={styles.enumNoElements}>
              <i>No elements</i>
            </div>
          )}
          <UL>
            {validator.enum.map((element, i) => (
              <li key={i}>
                <div className={styles.enumValue}>
                  <code>{element.value}</code>
                  {isEditing && (
                    <Button
                      className={styles.enumRemoveButton}
                      minimal
                      icon="remove"
                      onClick={() => {
                        onChange({
                          ...validator,
                          enum: removeAt(validator.enum, i),
                        });
                      }}
                    />
                  )}
                </div>
                <DescriptionInput
                  small
                  editable={editable}
                  value={element.description}
                  onChange={e => {
                    onChange({
                      ...validator,
                      enum: setAt(validator.enum, i, {
                        ...validator.enum[i],
                        description: e.currentTarget.value,
                      }),
                    });
                  }}
                />
              </li>
            ))}
            {isEditing && (
              <li>
                <ControlGroup>
                  <InputGroup
                    placeholder="Add element"
                    value={newEnumValue}
                    onChange={e => {
                      setNewEnumValue(e.currentTarget.value);
                    }}
                  />
                  <Button
                    icon="add"
                    onClick={() => {
                      if (!newEnumValue) {
                        return;
                      }

                      onChange({
                        ...validator,
                        enum: [
                          ...validator.enum,
                          {
                            value: newEnumValue,
                            description: '',
                          },
                        ],
                      });
                      setNewEnumValue('');
                    }}
                  />
                </ControlGroup>
              </li>
            )}
          </UL>
        </div>
      )}

      {isEditing && (
        <Button
          minimal
          small
          intent="primary"
          text="Apply"
          onClick={() => {
            setIsEditing(false);
          }}
        />
      )}
    </div>
  );
};
