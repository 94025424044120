import {escapeRegExp} from 'lodash-es';
import {Client} from './proto/deeplay/category_store/access_control/v2/access_control';

export function hasPermission(
  client: Client,
  categorization: string,
  action: 'read' | 'write' | 'manage',
): boolean {
  return client.permissions.some(permission => {
    if (action === 'read' && !permission.read) {
      return false;
    }

    if (action === 'write' && !permission.write) {
      return false;
    }

    if (action === 'manage' && !permission.manage) {
      return false;
    }

    const categorizationRegexp = new RegExp(
      `^${permission.categorizationPattern
        .split('*')
        .map(part => escapeRegExp(part))
        .join('.*')}$`,
    );

    return categorizationRegexp.test(categorization);
  });
}
