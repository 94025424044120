import {FormGroup} from '@blueprintjs/core';
import React, {memo} from 'react';
import {Schema} from './proto/deeplay/category_store/v2/category_store';
import {Attributes} from './types';
import {ValueInput} from './ValueInput';

export type SetQueryEditorProps = {
  className?: string;
  schema: Schema;
  value: Attributes;
  onChange(value: Attributes): void;
};

export const SetQueryEditor: React.FC<SetQueryEditorProps> = memo(props => {
  const {className, schema, value, onChange} = props;

  return (
    <div className={className}>
      {schema.attributes.map((attribute, i) => (
        <FormGroup
          key={i}
          label={attribute.key}
          labelInfo={attribute.isImplicit && '(implicit)'}
        >
          <ValueInput
            validator={attribute.validator}
            value={value.get(attribute.key) ?? ''}
            onChange={nextValue => {
              const nextAttributes = new Map(value);

              nextAttributes.set(attribute.key, nextValue);

              onChange(nextAttributes);
            }}
          />
        </FormGroup>
      ))}
    </div>
  );
});
