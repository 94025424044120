import {isAbortError} from 'abort-controller-x';
import {ClientError, ClientMiddleware, Status} from 'nice-grpc-web';

export function createLoggerMiddleware(): ClientMiddleware {
  return async function* loggerMiddleware(call, options) {
    const {path} = call.method;

    console.log(
      'Client call',
      path,
      'start',
      !call.requestStream ? call.request : undefined,
    );

    try {
      const result = yield* call.next(call.request, options);

      console.log('Client call', path, 'end: OK', result);

      return result;
    } catch (error) {
      if (error instanceof ClientError) {
        console.log(
          'Client call',
          path,
          `end: ${Status[error.code]}: ${error.details}`,
        );
      } else if (isAbortError(error)) {
        console.log('Client call', path, 'cancel');
      } else {
        console.log('Client call', path, `error: ${error?.stack}`);
      }

      throw error;
    }
  };
}
