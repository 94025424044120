import {
  Button,
  ButtonGroup,
  ControlGroup,
  FormGroup,
  Intent,
} from '@blueprintjs/core';
import React, {memo, useEffect, useState} from 'react';
import {stylesheet} from 'typestyle';
import {useCategoryStoreClient} from './AuthContext';
import {ClientError} from 'nice-grpc-web';
import {Schema} from './proto/deeplay/category_store/v2/category_store';
import {SetQueryEditor} from './SetQueryEditor';
import {toaster} from './toaster';
import {Attributes} from './types';
import {ValueInput} from './ValueInput';

export type SetQueryPanelProps = {
  categorization: string;
  schema: Schema;
};

const styles = stylesheet({
  query: {
    display: 'flex',
  },

  attributesSection: {
    flex: '0 0 200px',
    paddingRight: 9,
  },

  resultSection: {
    paddingLeft: 9,
    flex: '0 0 200px',
  },
});

export const SetQueryPanel: React.FC<SetQueryPanelProps> = memo(props => {
  const {categorization, schema} = props;

  const categoryStoreClient = useCategoryStoreClient();

  const [attributes, setAttributes] = useState<Attributes>(() =>
    createInitialAttributes(schema),
  );

  const [result, setResult] = useState<string | undefined>('');

  useEffect(() => {
    setAttributes(createInitialAttributes(schema));
  }, [schema]);

  return (
    <div>
      <div className={styles.query}>
        <div className={styles.attributesSection}>
          <SetQueryEditor
            schema={schema}
            value={attributes}
            onChange={setAttributes}
          />
        </div>

        <div className={styles.resultSection}>
          <FormGroup label="Result">
            <ControlGroup vertical fill>
              <ButtonGroup fill>
                <Button
                  text="Set"
                  outlined
                  active={result !== undefined}
                  onClick={() => {
                    setResult(result => (result === undefined ? '' : result));
                  }}
                />
                <Button
                  text="Clear"
                  outlined
                  active={result === undefined}
                  onClick={() => {
                    setResult(undefined);
                  }}
                />
              </ButtonGroup>
              {result !== undefined && (
                <ValueInput
                  validator={schema.resultValidator}
                  value={result}
                  onChange={value => {
                    setResult(value);
                  }}
                />
              )}
            </ControlGroup>
          </FormGroup>
        </div>
      </div>

      <Button
        intent="primary"
        large
        minimal
        outlined
        onClick={() => {
          categoryStoreClient
            .set({
              categorization,
              entries: [
                {
                  attributes: Array.from(attributes, ([key, value]) => ({
                    key,
                    value,
                  })),
                  result,
                },
              ],
            })
            .then(
              () => {
                toaster.show({
                  message: 'Set request succeeded',
                  intent: Intent.SUCCESS,
                });
              },
              error => {
                const errorText =
                  error instanceof ClientError ? error.details : error?.message;

                toaster.show({
                  message: `Set request failed: ${errorText}`,
                  intent: Intent.DANGER,
                });
              },
            );
        }}
      >
        Execute
      </Button>
    </div>
  );
});

function createInitialAttributes(schema: Schema): Attributes {
  const attributes: Attributes = new Map();

  return attributes;
}
