import React, {createContext, useContext, useMemo} from 'react';
import {Client} from './proto/deeplay/category_store/access_control/v2/access_control';
import {Client as GrpcClient} from 'nice-grpc-web';
import {CategoryStoreDefinition} from './proto/deeplay/category_store/v2/category_store';
import {AccessControlDefinition} from './proto/deeplay/category_store/access_control/v2/access_control';

export type AuthContext = {
  client: Client;
  categoryStoreClient: GrpcClient<typeof CategoryStoreDefinition>;
  accessControlClient: GrpcClient<typeof AccessControlDefinition>;
};

const context = createContext<AuthContext | null>(null);

export const AuthContextProvider: React.FC<AuthContext> = props => {
  const {client, categoryStoreClient, accessControlClient, children} = props;

  const value = useMemo(
    () => ({client, categoryStoreClient, accessControlClient}),
    [client, categoryStoreClient, accessControlClient],
  );

  return <context.Provider value={value}>{children}</context.Provider>;
};

export function useClient(): Client {
  return useAuthContext().client;
}

export function useCategoryStoreClient(): GrpcClient<
  typeof CategoryStoreDefinition
> {
  return useAuthContext().categoryStoreClient;
}

export function useAccessControlClient(): GrpcClient<
  typeof AccessControlDefinition
> {
  return useAuthContext().accessControlClient;
}

function useAuthContext(): AuthContext {
  const value = useContext(context);

  if (value == null) {
    throw new Error('Not authenticated');
  }

  return value;
}
