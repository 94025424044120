import {
  Alert,
  Button,
  Classes,
  FormGroup,
  H4,
  HTMLTable,
  InputGroup,
  Intent,
  Switch,
} from '@blueprintjs/core';
import React, {useEffect, useState} from 'react';
import {removeAt, setAt} from 'typescript-immutable-utils';
import {stylesheet} from 'typestyle';
import {useAccessControlClient} from './AuthContext';
import {ClientError} from 'nice-grpc-web';
import {Client} from './proto/deeplay/category_store/access_control/v2/access_control';
import {toaster} from './toaster';

export type ClientViewProps = {
  token: string;
  client: Client;
  onSave(entry: {token: string; client: Client}): void;
  onDelete(token: string): void;
};

const styles = stylesheet({
  root: {
    paddingTop: 60,
  },

  inputs: {
    width: 300,
  },

  permissionsTable: {
    width: '100%',
  },

  permissionCell: {
    verticalAlign: 'middle !important',
  },
  permissionSwitch: {
    margin: 0,
  },

  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const ClientView: React.FC<ClientViewProps> = props => {
  const {token, client, onSave, onDelete} = props;

  const accessControlClient = useAccessControlClient();

  const [clientValues, setClientValues] = useState(() => client);

  useEffect(() => {
    setClientValues(client);
  }, [client]);

  const [showToken, setShowToken] = useState(false);

  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  return (
    <div className={styles.root}>
      <div className={styles.inputs}>
        <FormGroup label="Client Id">
          <InputGroup
            value={clientValues.clientId}
            onChange={e => {
              const clientId = e.currentTarget.value;

              setClientValues(values => ({
                ...values,
                clientId,
              }));
            }}
          />
        </FormGroup>

        <FormGroup label="Token">
          <InputGroup
            readOnly
            className={Classes.MONOSPACE_TEXT}
            value={token}
            type={showToken ? 'text' : 'password'}
            rightElement={
              <Button
                minimal
                icon={showToken ? 'eye-off' : 'eye-open'}
                onClick={() => {
                  setShowToken(value => !value);
                }}
              />
            }
          />
        </FormGroup>
      </div>

      <H4>Permissions</H4>
      <HTMLTable className={styles.permissionsTable}>
        <thead>
          <tr>
            <th style={{width: '99%'}}>Categorization pattern</th>
            <th>Read</th>
            <th>Write</th>
            <th>Manage</th>
            <th style={{minWidth: '52px'}}></th>
          </tr>
        </thead>
        <tbody>
          {clientValues.permissions.map((permission, i) => (
            <tr key={i}>
              <td>
                <InputGroup
                  value={permission.categorizationPattern}
                  onChange={e => {
                    const categorizationPattern = e.currentTarget.value;

                    setClientValues(values => ({
                      ...values,
                      permissions: setAt(values.permissions, i, {
                        ...permission,
                        categorizationPattern,
                      }),
                    }));
                  }}
                />
              </td>
              <td className={styles.permissionCell}>
                <Switch
                  className={styles.permissionSwitch}
                  large
                  checked={permission.read}
                  onChange={e => {
                    const read = e.currentTarget.checked;

                    setClientValues(values => ({
                      ...values,
                      permissions: setAt(values.permissions, i, {
                        ...permission,
                        read,
                      }),
                    }));
                  }}
                />
              </td>
              <td className={styles.permissionCell}>
                <Switch
                  className={styles.permissionSwitch}
                  large
                  checked={permission.write}
                  onChange={e => {
                    const write = e.currentTarget.checked;

                    setClientValues(values => ({
                      ...values,
                      permissions: setAt(values.permissions, i, {
                        ...permission,
                        write,
                      }),
                    }));
                  }}
                />
              </td>
              <td className={styles.permissionCell}>
                <Switch
                  className={styles.permissionSwitch}
                  large
                  checked={permission.manage}
                  onChange={e => {
                    const manage = e.currentTarget.checked;

                    setClientValues(values => ({
                      ...values,
                      permissions: setAt(values.permissions, i, {
                        ...permission,
                        manage,
                      }),
                    }));
                  }}
                />
              </td>
              <td>
                <Button
                  minimal
                  icon="remove"
                  onClick={() => {
                    setClientValues(values => ({
                      ...values,
                      permissions: removeAt(values.permissions, i),
                    }));
                  }}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={999}>
              <Button
                minimal
                icon="add"
                text="Add"
                onClick={() => {
                  setClientValues(values =>
                    Client.fromPartial({
                      ...values,
                      permissions: [
                        ...values.permissions,
                        {
                          categorizationPattern: '',
                          read: false,
                          write: false,
                          manage: false,
                        },
                      ],
                    }),
                  );
                }}
              />
            </td>
          </tr>
        </tbody>
      </HTMLTable>

      <div className={styles.buttons}>
        <Button
          text="Save"
          intent="primary"
          large
          minimal
          outlined
          onClick={() => {
            const client = clientValues;

            accessControlClient.setClient({token, client}).then(
              () => {
                toaster.show({
                  message: 'Client saved',
                  intent: Intent.SUCCESS,
                });

                onSave({token, client});
              },
              error => {
                const errorText =
                  error instanceof ClientError ? error.details : error?.message;

                toaster.show({
                  message: `Failed to save client: ${errorText}`,
                  intent: Intent.DANGER,
                });
              },
            );
          }}
        />

        <Button
          text="Delete"
          intent="danger"
          large
          minimal
          outlined
          onClick={() => {
            setIsDeleteAlertOpen(true);
          }}
        />
        <Alert
          canEscapeKeyCancel
          canOutsideClickCancel
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          isOpen={isDeleteAlertOpen}
          intent="danger"
          icon="trash"
          onCancel={() => {
            setIsDeleteAlertOpen(false);
          }}
          onConfirm={() => {
            accessControlClient.setClient({token}).then(
              () => {
                toaster.show({
                  message: 'Client deleted',
                  intent: Intent.SUCCESS,
                });

                onDelete(token);
                setIsDeleteAlertOpen(false);
              },
              error => {
                const errorText =
                  error instanceof ClientError ? error.details : error?.message;

                toaster.show({
                  message: `Failed to delete client: ${errorText}`,
                  intent: Intent.DANGER,
                });
              },
            );
          }}
        >
          <p>Are you sure you want to delete client?</p>
        </Alert>
      </div>
    </div>
  );
};
