import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core';
import {nanoid} from 'nanoid';
import React, {useState} from 'react';
import {stylesheet} from 'typestyle';
import {useAccessControlClient} from './AuthContext';
import {ClientError} from 'nice-grpc-web';
import {Client} from './proto/deeplay/category_store/access_control/v2/access_control';
import {toaster} from './toaster';

export type ClientFormProps = {
  onCreate(entry: {token: string; client: Client}): void;
};

const styles = stylesheet({
  root: {
    width: 300,
    paddingTop: 60,
  },
});

export const ClientForm: React.FC<ClientFormProps> = props => {
  const {onCreate} = props;

  const accessControlClient = useAccessControlClient();

  const [clientId, setClientId] = useState('');
  const [token] = useState(() => nanoid());
  const [showToken, setShowToken] = useState(false);

  return (
    <div className={styles.root}>
      <FormGroup label="Client Id">
        <InputGroup
          value={clientId}
          onChange={e => {
            setClientId(e.currentTarget.value);
          }}
        />
      </FormGroup>

      <FormGroup label="Token">
        <InputGroup
          readOnly
          className={Classes.MONOSPACE_TEXT}
          value={token}
          type={showToken ? 'text' : 'password'}
          rightElement={
            <Button
              minimal
              icon={showToken ? 'eye-off' : 'eye-open'}
              onClick={() => {
                setShowToken(value => !value);
              }}
            />
          }
        />
      </FormGroup>

      <Button
        text="Create"
        intent="primary"
        large
        minimal
        outlined
        onClick={() => {
          const client = Client.fromPartial({clientId});

          accessControlClient.setClient({client, token}).then(
            () => {
              onCreate({token, client});
            },
            error => {
              const errorText =
                error instanceof ClientError ? error.details : error?.message;

              toaster.show({
                message: `Failed to create client: ${errorText}`,
                intent: Intent.DANGER,
              });
            },
          );
        }}
      />
    </div>
  );
};
