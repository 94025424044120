import {Classes, Colors, TextArea} from '@blueprintjs/core';
import React, {useState} from 'react';
import {classes, stylesheet} from 'typestyle';

export type DescriptionInputProps = {
  small?: boolean;
  editable?: boolean;
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
};

const styles = stylesheet({
  root: {
    marginTop: 5,
    marginBottom: 5,
  },

  description: {
    whiteSpace: 'pre-wrap',
    padding: '5px !important',
    borderRadius: 3,
  },

  descriptionSmall: {
    fontSize: '13px !important',
  },

  editable: {
    cursor: 'pointer',

    $nest: {
      '&:hover': {
        backgroundColor: Colors.WHITE,
        boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.15)',
      },
    },
  },
});

export const DescriptionInput: React.FC<DescriptionInputProps> = props => {
  const {small, editable, value, onChange} = props;

  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className={styles.root}>
      {isEditing ? (
        <TextArea
          className={classes(
            styles.description,
            {[styles.descriptionSmall]: small},
            Classes.MONOSPACE_TEXT,
          )}
          fill
          rows={1}
          growVertically
          autoFocus
          value={value}
          onChange={onChange}
          onFocus={e => {
            e.currentTarget.setSelectionRange(value.length, value.length);
          }}
          onBlur={() => {
            setIsEditing(false);
          }}
        />
      ) : (
        <div
          className={classes(
            styles.description,
            {[styles.descriptionSmall]: small},
            {[styles.editable]: editable},
            Classes.MONOSPACE_TEXT,
          )}
          onClick={() => {
            if (editable) {
              setIsEditing(true);
            }
          }}
          onFocus={() => {
            if (editable) {
              setIsEditing(true);
            }
          }}
          tabIndex={editable ? 0 : undefined}
        >
          {value || <i>No description</i>}
        </div>
      )}
    </div>
  );
};
