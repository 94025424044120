import {rethrowAbortError, delay} from 'abort-controller-x';
import {ClientError, ClientMiddleware, Status} from 'nice-grpc-web';

export type RetryCallOptions = {
  /**
   * Starting delay before first retry attempt in milliseconds.
   *
   * Defaults to 1000.
   *
   * Example: if `baseMs` is 100, then retries will be attempted in 100ms,
   * 200ms, 400ms etc (not counting jitter).
   */
  retryBaseDelayMs?: number;
  /**
   * Maximum delay between attempts in milliseconds.
   *
   * Defaults to 15 seconds.
   *
   * Example: if `baseMs` is 1000 and `maxDelayMs` is 3000, then retries will be
   * attempted in 1000ms, 2000ms, 3000ms, 3000ms etc (not counting jitter).
   */
  retryMaxDelayMs?: number;
  /**
   * Maximum for the total number of attempts.
   *
   * Defaults to `Infinity`.
   */
  retryMaxAttempts?: number;
  /**
   * Called after each failed attempt before setting delay timer.
   *
   * Rethrow error from this callback to prevent further retries.
   */
  onRetry?: (error: unknown, attempt: number, delayMs: number) => void;
  retryStatuses?: 'idempotent' | Status[];
};

const idempotentRetryStatuses: Status[] = [
  Status.UNKNOWN,
  Status.RESOURCE_EXHAUSTED,
  Status.ABORTED,
  Status.UNIMPLEMENTED,
  Status.INTERNAL,
  Status.UNAVAILABLE,
];

export function createRetryMiddleware(): ClientMiddleware<RetryCallOptions> {
  return async function* retryMiddleware(call, options) {
    const {idempotencyLevel} = call.method.options;
    const isIdempotent =
      idempotencyLevel === 'IDEMPOTENT' ||
      idempotencyLevel === 'NO_SIDE_EFFECTS';

    const {
      retryBaseDelayMs = 1000,
      retryMaxDelayMs = 15000,
      retryMaxAttempts = options.signal != null ? Infinity : 1,
      onRetry,
      retryStatuses = isIdempotent ? 'idempotent' : undefined,
      ...restOptions
    } = options;

    if (
      call.requestStream ||
      call.responseStream ||
      retryStatuses == null ||
      retryStatuses.length === 0
    ) {
      return yield* call.next(call.request, restOptions);
    }

    const signal = options.signal ?? new AbortController().signal;

    for (let attempt = 0; ; attempt++) {
      try {
        return yield* call.next(call.request, restOptions);
      } catch (error) {
        rethrowAbortError(error);

        const statuses =
          retryStatuses === 'idempotent'
            ? idempotentRetryStatuses
            : retryStatuses;

        if (error instanceof ClientError && !statuses.includes(error.code)) {
          throw error;
        }

        if (attempt >= retryMaxAttempts) {
          throw error;
        }

        // https://aws.amazon.com/ru/blogs/architecture/exponential-backoff-and-jitter/
        const backoff = Math.min(
          retryMaxDelayMs,
          Math.pow(2, attempt) * retryBaseDelayMs,
        );
        const delayMs = Math.round((backoff * (1 + Math.random())) / 2);

        if (onRetry) {
          onRetry(error, attempt, delayMs);
        }

        await delay(signal, delayMs);
      }
    }
  };
}
