import {Button, Card, Colors, InputGroup, Spinner} from '@blueprintjs/core';
import {useMemo, useState} from 'react';
import {stylesheet} from 'typestyle';
import {App} from './App';
import {AuthContextProvider} from './AuthContext';
import {
  ClientError,
  ClientMiddleware,
  createClientFactory,
  Status,
  Metadata,
  createChannel,
} from 'nice-grpc-web';
import {AccessControlDefinition} from './proto/deeplay/category_store/access_control/v2/access_control';
import {CategoryStoreDefinition} from './proto/deeplay/category_store/v2/category_store';
import {Delay} from './utils/Delay';
import {createLoggerMiddleware} from './utils/loggerMiddleware';
import {createRetryMiddleware} from './utils/retryMiddleware';
import {useQuery} from './utils/useQuery';

const clientFactory = createClientFactory()
  .use(createLoggerMiddleware())
  .use(createRetryMiddleware());

function createAuthMiddleware(token: string): ClientMiddleware {
  return async function* authMiddleware(call, options) {
    return yield* call.next(call.request, {
      ...options,
      metadata: Metadata(options.metadata).set(
        'Authorization',
        `Bearer ${token}`,
      ),
    });
  };
}

const accessControlLoginClient = clientFactory.create(
  AccessControlDefinition,
  createChannel(window.location.origin),
  {
    '*': {
      retryStatuses: 'idempotent',
    },
  },
);

const styles = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: Colors.LIGHT_GRAY5,
  },

  linkText: {
    textAlign: 'center',
    display: 'block',
  },

  card: {
    width: 350,
    backgroundColor: Colors.WHITE,
  },

  signInButton: {
    display: 'block',
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export const Auth: React.FC = () => {
  const [token, setToken] = useState(
    () => sessionStorage.getItem('token') ?? '',
  );

  const clientQuery = useQuery(
    async signal => {
      if (!token) {
        return;
      }

      const response = await accessControlLoginClient.getClients(
        {},
        {
          metadata: Metadata({Authorization: `Bearer ${token}`}),
          signal,
        },
      );

      sessionStorage.setItem('token', token);
      setInputToken('');

      const entry = response.entries.find(entry => entry.token === token);

      return entry?.client;
    },
    [token],
  );

  const {categoryStoreClient, accessControlClient} = useMemo(() => {
    const authMiddleware = createAuthMiddleware(token);

    const authClientFactory = clientFactory.use(authMiddleware);

    return {
      categoryStoreClient: authClientFactory.create(
        CategoryStoreDefinition,
        createChannel(window.location.origin),
      ),
      accessControlClient: authClientFactory.create(
        AccessControlDefinition,
        createChannel(window.location.origin),
      ),
    };
  }, [token]);

  const [inputToken, setInputToken] = useState('');

  if (clientQuery.status === 'loading' && !inputToken) {
    return null;
  }

  if (clientQuery.status === 'done' && clientQuery.result != null) {
    return (
      <AuthContextProvider
        client={clientQuery.result}
        categoryStoreClient={categoryStoreClient}
        accessControlClient={accessControlClient}
      >
        <App
          onSignOutClick={() => {
            sessionStorage.removeItem('token');
            setToken('');
          }}
        />
      </AuthContextProvider>
    );
  }

  return (
    <div className={styles.root}>
      <div>
        <Card className={styles.card}>
          <form
            onSubmit={e => {
              e.preventDefault();
              setToken(inputToken.trim());
            }}
          >
            <InputGroup
              large
              placeholder="Token"
              type="password"
              intent={
                token === inputToken.trim() &&
                clientQuery.status === 'error' &&
                clientQuery.error instanceof ClientError &&
                clientQuery.error.code === Status.UNAUTHENTICATED
                  ? 'danger'
                  : 'none'
              }
              value={inputToken}
              onChange={e => {
                setInputToken(e.currentTarget.value);
              }}
              rightElement={
                clientQuery.status === 'loading' ? (
                  <Delay>
                    <Spinner size={15} />
                  </Delay>
                ) : undefined
              }
            />
            <Button
              className={styles.signInButton}
              large
              outlined
              intent="primary"
              text="Sign in"
              type="submit"
            />
          </form>
        </Card>
      </div>
      <div>
        <a
          className={styles.linkText}
          href="https://docs.category-store.data.deeplay.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Документация по сервису категорий
        </a>
      </div>
    </div>
  );
};
