import {MenuItem, TagInput} from '@blueprintjs/core';
import {ItemPredicate, ItemRenderer, MultiSelect} from '@blueprintjs/select';
import React, {memo, useCallback, useMemo} from 'react';
import {removeAt} from 'typescript-immutable-utils';
import {stylesheet} from 'typestyle';
import {
  Schema_Validator,
  Schema_Validator_Enum_Element,
} from './proto/deeplay/category_store/v2/category_store';
import {emptyArray} from './utils/empty';

export type SelectorInputProps = {
  validator?: Schema_Validator;
  value: string[];
  onChange(value: string[]): void;
  disabled?: boolean;
};

const styles = stylesheet({
  popoverTarget: {
    $nest: {
      '& > *': {
        borderRadius: 'inherit',
      },
    },
  },
});

export const SelectorInput: React.FC<SelectorInputProps> = memo(props => {
  const {validator} = props;

  if (validator?.validator?.$case === 'enum') {
    return <EnumInput {...props} />;
  } else {
    return <TextInput {...props} />;
  }
});

const TextInput: React.FC<SelectorInputProps> = props => {
  const {value, onChange, disabled} = props;

  return (
    <TagInput
      fill
      disabled={disabled}
      values={value}
      addOnBlur
      onAdd={values => {
        onChange([...value, ...values]);
      }}
      onRemove={(_, index) => {
        onChange(removeAt(value, index));
      }}
    />
  );
};

const EnumInput: React.FC<SelectorInputProps> = props => {
  const {validator, value, onChange, disabled} = props;

  const enumElements =
    validator?.validator?.$case === 'enum'
      ? validator.validator.enum.elements
      : emptyArray;

  const selectedItems = useMemo(() => {
    const selectedItems: Schema_Validator_Enum_Element[] = [];

    for (const item of value) {
      const enumElement = enumElements.find(element => element.value === item);

      if (enumElement) {
        selectedItems.push(enumElement);
      }
    }

    return selectedItems;
  }, [enumElements, value]);

  const enumItemRenderer = useCallback<
    ItemRenderer<Schema_Validator_Enum_Element>
  >(
    (item, {handleClick, modifiers}) => {
      if (!modifiers.matchesPredicate) {
        return null;
      }

      return (
        <MenuItem
          key={item.value}
          active={modifiers.active}
          disabled={modifiers.disabled}
          onClick={handleClick}
          text={item.value}
          icon={value.includes(item.value) ? 'tick' : 'blank'}
        />
      );
    },
    [value],
  );

  const handleItemSelect = useCallback(
    (item: Schema_Validator_Enum_Element) => {
      const index = value.indexOf(item.value);

      if (index === -1) {
        onChange([...value, item.value]);
      } else {
        onChange(removeAt(value, index));
      }
    },
    [onChange, value],
  );

  return (
    <MultiSelect<Schema_Validator_Enum_Element>
      fill
      popoverProps={{targetClassName: styles.popoverTarget}}
      items={enumElements}
      itemRenderer={enumItemRenderer}
      tagInputProps={{disabled}}
      tagRenderer={enumTagRenderer}
      itemPredicate={enumItemPredicate}
      selectedItems={selectedItems}
      onItemSelect={handleItemSelect}
    />
  );
};

const enumItemPredicate: ItemPredicate<Schema_Validator_Enum_Element> = (
  query,
  item,
) => !query || item.value.toLowerCase().startsWith(query.toLowerCase());

const enumTagRenderer = (item: Schema_Validator_Enum_Element) => (
  <span>{item.value}</span>
);
