import {run} from 'abort-controller-x';
import {DependencyList, useEffect} from 'react';

export function useAbortableEffect(
  fn: (signal: AbortSignal) => Promise<void | never>,
  deps: DependencyList,
): void {
  useEffect(() => {
    const stop = run(fn);

    return () => {
      stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
