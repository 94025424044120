import {
  Button,
  Callout,
  Colors,
  H4,
  Icon,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import {all} from 'abort-controller-x';
import React, {memo, useEffect, useState} from 'react';
import {stylesheet} from 'typestyle';
import {useCategoryStoreClient} from './AuthContext';
import {GetQueryEditor} from './GetQueryEditor';
import {ClientError} from 'nice-grpc-web';
import {Schema} from './proto/deeplay/category_store/v2/category_store';
import {Attributes} from './types';
import {Delay} from './utils/Delay';
import {useQuery} from './utils/useQuery';

export type GetQueryPanelProps = {
  categorization: string;
  schema: Schema;
};

const styles = stylesheet({
  root: {
    display: 'flex',
  },

  querySection: {
    flex: '0 0 200px',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: Colors.LIGHT_GRAY1,
    paddingRight: 8,
  },

  executeButton: {
    marginTop: 8,
    marginBottom: 8,
  },

  resultsSection: {
    flex: '1 1 0',
    paddingLeft: 9,
  },

  entrySection: {
    marginBottom: 15,
  },

  entry: {
    display: 'flex',
    alignItems: 'center',
  },

  entryAttributes: {
    flex: '1 1 0',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: 'break-spaces',
  },

  entryResult: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
  },

  rule: {
    display: 'flex',
    alignItems: 'center',
  },

  ruleFromSelectors: {
    flex: '1 1 0',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: 'break-spaces',
  },

  ruleToAttributes: {
    flex: '1 1 0',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: 'break-spaces',
  },
});

export const GetQueryPanel: React.FC<GetQueryPanelProps> = memo(props => {
  const {categorization, schema} = props;

  const [attributes, setAttributes] = useState<Attributes>(() =>
    createInitialAttributes(schema),
  );

  const categoryStoreClient = useCategoryStoreClient();

  useEffect(() => {
    setAttributes(createInitialAttributes(schema));
  }, [schema]);

  const [queryParams, setQueryParams] = useState<{
    categorization: string;
    attributes: Attributes;
  }>();

  const queryState = useQuery(
    async signal => {
      if (queryParams == null) {
        return null;
      }

      const {categorization, attributes} = queryParams;

      const [getResponse, getRulesResponse] = await all(signal, signal => [
        categoryStoreClient.get(
          {
            categorization,
            selectors: Array.from(attributes, ([key, value]) => ({
              key,
              valueIn: [value],
            })),
          },
          {signal},
        ),
        categoryStoreClient.getRules(
          {
            categorization,
            forAttributes: Array.from(attributes, ([key, value]) => ({
              key,
              value,
            })),
          },
          {signal},
        ),
      ]);

      const [entry = undefined] = getResponse.entries;

      return {
        entry,
        rules: getRulesResponse.rules,
      };
    },
    [queryParams],
  );

  return (
    <div className={styles.root}>
      <div className={styles.querySection}>
        <GetQueryEditor
          schema={schema}
          value={attributes}
          onChange={setAttributes}
        />

        <Button
          className={styles.executeButton}
          intent="primary"
          large
          minimal
          outlined
          onClick={() => {
            setQueryParams({categorization, attributes});
          }}
        >
          Execute
        </Button>
      </div>

      <div className={styles.resultsSection}>
        {queryState.status === 'loading' && (
          <Delay>
            <Spinner />
          </Delay>
        )}

        {queryState.status === 'error' && (
          <NonIdealState
            icon="error"
            title="Error"
            description={
              queryState.error instanceof ClientError
                ? queryState.error.details
                : (queryState.error as any)?.message
            }
          />
        )}

        {queryState.status === 'done' && queryState.result != null && (
          <>
            <div className={styles.entrySection}>
              <H4>Entry</H4>

              {queryState.result.entry == null ? (
                <div>Not found</div>
              ) : (
                <Callout className={styles.entry}>
                  <pre className={styles.entryAttributes}>
                    {queryState.result.entry.attributes
                      .map(attribute => `${attribute.key} = ${attribute.value}`)
                      .join('\n')}
                  </pre>
                  <Icon icon="arrow-right" />
                  <pre className={styles.entryResult}>
                    {queryState.result.entry.result}
                  </pre>
                </Callout>
              )}
            </div>

            <div>
              <H4>Applied rules</H4>

              {queryState.result.rules.length === 0 ? (
                <div>No rules</div>
              ) : (
                queryState.result.rules.map((rule, i) => (
                  <Callout key={i} className={styles.rule}>
                    <pre className={styles.ruleFromSelectors}>
                      {rule.fromSelectors
                        .map(({key, valueIn}) =>
                          valueIn.length === 0
                            ? `${key} = *`
                            : valueIn.length === 1
                            ? `${key} = ${valueIn[0]}`
                            : `${key} in (${valueIn.join(', ')})`,
                        )
                        .join('\n')}
                    </pre>
                    <Icon icon="arrow-right" />
                    <pre className={styles.ruleToAttributes}>
                      {rule.toAttributes
                        .map(
                          attribute => `${attribute.key} = ${attribute.value}`,
                        )
                        .join('\n')}
                    </pre>
                  </Callout>
                ))
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

function createInitialAttributes(schema: Schema): Attributes {
  const attributes: Attributes = new Map();

  for (const attribute of schema.attributes) {
    if (!attribute.isImplicit) {
      attributes.set(attribute.key, '');
    }
  }

  return attributes;
}
