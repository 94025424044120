import {
  Alignment,
  Button,
  ButtonGroup,
  Classes,
  ControlGroup,
  FormGroup,
  Icon,
  Switch,
} from '@blueprintjs/core';
import React, {memo, useState} from 'react';
import {stylesheet} from 'typestyle';
import {Schema} from './proto/deeplay/category_store/v2/category_store';
import {SelectorInput} from './SelectorInput';
import {Rule} from './types';
import {emptyArray} from './utils/empty';
import {ValueInput} from './ValueInput';

export type RuleEditorProps = {
  schema: Schema;
  value: Rule;
  onChange(value: Rule): void;
};

const styles = stylesheet({
  root: {
    display: 'flex',
    paddingLeft: 29,
    paddingRight: 29,
  },

  fromSelectors: {
    flex: '1 1 0',
    marginLeft: 10,
    marginRight: 10,
  },

  arrow: {
    alignSelf: 'center',
  },

  toAttributes: {
    flex: '1 1 0',
    marginLeft: 10,
    marginRight: 10,
  },

  switchLabel: {
    marginBottom: '5px !important',
  },
});

export const RuleEditor: React.FC<RuleEditorProps> = memo(props => {
  const {schema, value, onChange} = props;

  const [wildcards, setWildcards] = useState<Set<string>>(() => new Set());

  return (
    <div className={styles.root}>
      <div className={styles.fromSelectors}>
        {schema.attributes.map((attribute, i) => (
          <div key={i}>
            <FormGroup
              label={attribute.key}
              labelInfo={attribute.isImplicit && '(implicit)'}
            >
              <ControlGroup vertical fill>
                <ButtonGroup fill>
                  {attribute.isImplicit && (
                    <Button
                      text="Unset"
                      outlined
                      active={!value.fromSelectors.has(attribute.key)}
                      onClick={() => {
                        const nextFromSelectors = new Map(value.fromSelectors);
                        nextFromSelectors.delete(attribute.key);

                        const nextToAttributes = new Map(value.toAttributes);
                        nextToAttributes.set(attribute.key, '');

                        onChange({
                          fromSelectors: nextFromSelectors,
                          toAttributes: nextToAttributes,
                        });
                      }}
                    />
                  )}
                  <Button
                    text="Any"
                    outlined
                    active={
                      value.fromSelectors.has(attribute.key) &&
                      wildcards.has(attribute.key)
                    }
                    onClick={() => {
                      const nextFromSelectors = new Map(value.fromSelectors);
                      nextFromSelectors.set(attribute.key, emptyArray);

                      const nextToAttributes = new Map(value.toAttributes);
                      nextToAttributes.delete(attribute.key);

                      onChange({
                        fromSelectors: nextFromSelectors,
                        toAttributes: nextToAttributes,
                      });

                      setWildcards(wildcards => {
                        const nextWildcards = new Set(wildcards);

                        nextWildcards.add(attribute.key);

                        return nextWildcards;
                      });
                    }}
                  />
                  <Button
                    text="One of"
                    outlined
                    active={
                      value.fromSelectors.has(attribute.key) &&
                      !wildcards.has(attribute.key)
                    }
                    onClick={() => {
                      const nextFromSelectors = new Map(value.fromSelectors);
                      nextFromSelectors.set(attribute.key, emptyArray);

                      const nextToAttributes = new Map(value.toAttributes);
                      nextToAttributes.delete(attribute.key);

                      onChange({
                        fromSelectors: nextFromSelectors,
                        toAttributes: nextToAttributes,
                      });

                      setWildcards(wildcards => {
                        const nextWildcards = new Set(wildcards);

                        nextWildcards.delete(attribute.key);

                        return nextWildcards;
                      });
                    }}
                  />
                </ButtonGroup>
                {value.fromSelectors.has(attribute.key) &&
                  !wildcards.has(attribute.key) && (
                    <SelectorInput
                      validator={attribute.validator}
                      value={
                        value.fromSelectors.get(attribute.key) ?? emptyArray
                      }
                      onChange={valueIn => {
                        const nextFromSelectors = new Map(value.fromSelectors);

                        nextFromSelectors.set(attribute.key, valueIn);

                        onChange({
                          fromSelectors: nextFromSelectors,
                          toAttributes: value.toAttributes,
                        });
                      }}
                    />
                  )}
              </ControlGroup>
            </FormGroup>
          </div>
        ))}
      </div>

      <Icon className={styles.arrow} icon="arrow-right" />

      <div className={styles.toAttributes}>
        {schema.attributes.map(
          (attribute, i) =>
            attribute.isImplicit &&
            !value.fromSelectors.has(attribute.key) && (
              <div key={i}>
                <Switch
                  className={styles.switchLabel}
                  alignIndicator={Alignment.RIGHT}
                  labelElement={
                    <>
                      {attribute.key}{' '}
                      <span className={Classes.TEXT_MUTED}>(implicit)</span>
                    </>
                  }
                  checked={value.toAttributes.has(attribute.key)}
                  onChange={e => {
                    const nextToAttributes = new Map(value.toAttributes);

                    if (e.currentTarget.checked) {
                      nextToAttributes.set(attribute.key, '');
                    } else {
                      nextToAttributes.delete(attribute.key);
                    }

                    onChange({
                      fromSelectors: value.fromSelectors,
                      toAttributes: nextToAttributes,
                    });
                  }}
                />
                <ValueInput
                  validator={attribute.validator}
                  disabled={!value.toAttributes.has(attribute.key)}
                  value={value.toAttributes.get(attribute.key) ?? ''}
                  onChange={attributeValue => {
                    const nextToAttributes = new Map(value.toAttributes);

                    nextToAttributes.set(attribute.key, attributeValue);

                    onChange({
                      fromSelectors: value.fromSelectors,
                      toAttributes: nextToAttributes,
                    });
                  }}
                />
              </div>
            ),
        )}
      </div>
    </div>
  );
});
