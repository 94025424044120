import {
  Button,
  ButtonGroup,
  Divider,
  H4,
  Icon,
  Intent,
  Spinner,
} from '@blueprintjs/core';
import React, {memo, useEffect, useState} from 'react';
import {removeAt} from 'typescript-immutable-utils';
import {stylesheet} from 'typestyle';
import {useCategoryStoreClient, useClient} from './AuthContext';
import {ClientError} from 'nice-grpc-web';
import {hasPermission} from './permissions';
import {Schema} from './proto/deeplay/category_store/v2/category_store';
import {RuleEditor} from './RuleEditor';
import {toaster} from './toaster';
import {AttributeSelectors, Rule} from './types';
import {Delay} from './utils/Delay';
import {emptyArray} from './utils/empty';
import {useAbortableEffect} from './utils/useAbortableEffect';

export type RulesPanelProps = {
  categorization: string;
  schema: Schema;
};

const styles = stylesheet({
  addRule: {
    marginBottom: 15,
  },

  addButton: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 150,
    textAlign: 'center',
  },

  ruleList: {
    marginBottom: 15,
  },

  rule: {
    display: 'flex',
    alignItems: 'center',
  },

  reorderButton: {
    height: 15,
    minHeight: 'auto',
  },

  ruleFromSelectors: {
    flex: '1 1 0',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: 'break-spaces',
  },

  ruleToAttributes: {
    flex: '1 1 0',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: 'break-spaces',
  },
});

export const RulesPanel: React.FC<RulesPanelProps> = memo(props => {
  const {categorization, schema} = props;

  const client = useClient();
  const categoryStoreClient = useCategoryStoreClient();

  const hasManagePermission = hasPermission(client, categorization, 'manage');

  const [rules, setRules] = useState<Rule[]>();

  const [newRule, setNewRule] = useState<Rule>(() => createInitialRule(schema));

  useEffect(() => {
    setNewRule(createInitialRule(schema));
  }, [schema]);

  useAbortableEffect(
    async signal => {
      setRules(undefined);

      const response = await categoryStoreClient.getRules(
        {categorization},
        {signal},
      );

      setRules(
        response.rules.map(rule => ({
          fromSelectors: new Map(
            rule.fromSelectors.map(selector => [
              selector.key,
              selector.valueIn,
            ]),
          ),
          toAttributes: new Map(
            rule.toAttributes.map(attribute => [
              attribute.key,
              attribute.value,
            ]),
          ),
        })),
      );
    },
    [categorization],
  );

  if (rules == null) {
    return (
      <Delay>
        <Spinner />
      </Delay>
    );
  }

  return (
    <div>
      {hasManagePermission && (
        <div className={styles.addRule}>
          <H4>Add rule</H4>
          <RuleEditor schema={schema} value={newRule} onChange={setNewRule} />

          <Button
            className={styles.addButton}
            text="Add"
            icon="bring-data"
            large
            minimal
            onClick={() => {
              setRules(rules => rules && [...rules, newRule]);
              setNewRule(createInitialRule(schema));
            }}
          />
        </div>
      )}

      <H4>Rule list</H4>
      <div className={styles.ruleList}>
        {rules.length === 0 ? (
          <div>No rules</div>
        ) : (
          rules.map((rule, i) => (
            <div key={i}>
              <div className={styles.rule}>
                <ButtonGroup vertical>
                  <Button
                    className={styles.reorderButton}
                    minimal
                    icon="chevron-up"
                    disabled={i === 0}
                    onClick={() => {
                      setRules(rules => {
                        if (rules == null) {
                          return rules;
                        }

                        const nextRules = [...rules];

                        nextRules[i - 1] = rules[i];
                        nextRules[i] = rules[i - 1];

                        return nextRules;
                      });
                    }}
                  />
                  <Button
                    className={styles.reorderButton}
                    minimal
                    icon="chevron-down"
                    disabled={i === rules.length - 1}
                    onClick={() => {
                      setRules(rules => {
                        if (rules == null) {
                          return rules;
                        }

                        const nextRules = [...rules];

                        nextRules[i + 1] = rules[i];
                        nextRules[i] = rules[i + 1];

                        return nextRules;
                      });
                    }}
                  />
                </ButtonGroup>
                <pre className={styles.ruleFromSelectors}>
                  {Array.from(rule.fromSelectors, ([key, valueIn]) =>
                    valueIn.length === 0
                      ? `${key} = *`
                      : valueIn.length === 1
                      ? `${key} = ${valueIn[0]}`
                      : `${key} in (${valueIn.join(', ')})`,
                  ).join('\n')}
                </pre>
                <Icon icon="arrow-right" />
                <pre className={styles.ruleToAttributes}>
                  {Array.from(
                    rule.toAttributes,
                    ([key, value]) => `${key} = ${value}`,
                  ).join('\n')}
                </pre>
                <Button
                  minimal
                  icon="delete"
                  onClick={() => {
                    setRules(rules => {
                      if (rules == null) {
                        return rules;
                      }

                      return removeAt(rules, i);
                    });
                  }}
                />
              </div>
              <Divider />
            </div>
          ))
        )}
      </div>

      {hasManagePermission && (
        <Button
          text="Save"
          intent="primary"
          large
          minimal
          outlined
          onClick={() => {
            categoryStoreClient
              .setRules({
                categorization,
                rules: rules.map(rule => ({
                  fromSelectors: Array.from(
                    rule.fromSelectors,
                    ([key, valueIn]) => ({key, valueIn}),
                  ),
                  toAttributes: Array.from(
                    rule.toAttributes,
                    ([key, value]) => ({key, value}),
                  ),
                })),
              })
              .then(
                () => {
                  toaster.show({
                    message: 'Rules saved',
                    intent: Intent.SUCCESS,
                  });
                },
                error => {
                  const errorText =
                    error instanceof ClientError
                      ? error.details
                      : error?.message;

                  toaster.show({
                    message: `Failed to save rules: ${errorText}`,
                    intent: Intent.DANGER,
                  });
                },
              );
          }}
        />
      )}
    </div>
  );
});

function createInitialRule(schema: Schema): Rule {
  const fromSelectors: AttributeSelectors = new Map();

  for (const attribute of schema.attributes) {
    if (!attribute.isImplicit) {
      fromSelectors.set(attribute.key, emptyArray);
    }
  }

  return {
    fromSelectors,
    toAttributes: new Map(),
  };
}
