import {
  Button,
  Callout,
  Colors,
  Icon,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core';
import {catchAbortError} from 'abort-controller-x';
import {last} from 'lodash-es';
import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {stylesheet} from 'typestyle';
import {useCategoryStoreClient} from './AuthContext';
import {ListQueryEditor} from './ListQueryEditor';
import {ClientError} from 'nice-grpc-web';
import {
  ListResponse_Entry,
  Schema,
} from './proto/deeplay/category_store/v2/category_store';
import {AttributeSelectors} from './types';
import {Delay} from './utils/Delay';
import {emptyArray} from './utils/empty';
import {useQuery} from './utils/useQuery';

export type ListQueryPanelProps = {
  categorization: string;
  schema: Schema;
};

const styles = stylesheet({
  root: {
    display: 'flex',
  },

  querySection: {
    flex: '0 0 200px',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: Colors.LIGHT_GRAY1,
    paddingRight: 8,
  },

  executeButton: {
    marginTop: 8,
    marginBottom: 8,
  },

  resultsSection: {
    flex: '1 1 0',
    paddingLeft: 9,
  },

  entry: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 2,
  },

  entryAttributes: {
    flex: '1 1 0',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: 'break-spaces',
  },

  entryResult: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
  },
});

const LIMIT = 20;

export const ListQueryPanel: React.FC<ListQueryPanelProps> = memo(props => {
  const {categorization, schema} = props;

  const [selectors, setSelectors] = useState<AttributeSelectors>(() =>
    createInitialSelectors(schema),
  );

  const categoryStoreClient = useCategoryStoreClient();

  useEffect(() => {
    setSelectors(createInitialSelectors(schema));
  }, [schema]);

  const [queryParams, setQueryParams] = useState<{
    categorization: string;
    selectors: AttributeSelectors;
  }>();

  const queryState = useQuery(
    async signal => {
      if (queryParams == null) {
        return null;
      }

      const {categorization, selectors} = queryParams;

      const listResponse = await categoryStoreClient.list(
        {
          categorization,
          selectors: Array.from(selectors, ([key, valueIn]) => ({
            key,
            valueIn,
          })),
          limit: LIMIT,
        },
        {
          signal,
        },
      );

      return listResponse;
    },
    [queryParams],
  );

  const [loadMoreState, setLoadMoreState] = useState<{
    isLoading: boolean;
    entries: ListResponse_Entry[];
    hasMore: boolean;
  }>();

  useEffect(() => {
    if (queryState.status === 'done') {
      setLoadMoreState(undefined);
    }
  }, [queryState]);

  const abortControllerRef = useRef<AbortController>();

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => {
      abortControllerRef.current!.abort();
    };
  }, []);

  const handleLoadMoreClick = useCallback(() => {
    const {signal} = abortControllerRef.current!;

    Promise.resolve()
      .then(async () => {
        if (queryState.status !== 'done') {
          return;
        }

        if (queryParams == null) {
          return;
        }

        const lastEntry = last(
          loadMoreState?.entries ?? queryState.result?.entries,
        );

        if (lastEntry == null) {
          return;
        }

        setLoadMoreState(state => ({
          isLoading: true,
          entries: state?.entries ?? emptyArray,
          hasMore: true,
        }));

        const {categorization, selectors} = queryParams;

        const listResponse = await categoryStoreClient.list(
          {
            categorization,
            selectors: Array.from(selectors, ([key, valueIn]) => ({
              key,
              valueIn,
            })),
            after: lastEntry.attributes,
            limit: LIMIT,
          },
          {
            signal,
          },
        );

        setLoadMoreState(
          state =>
            state && {
              isLoading: false,
              entries: [...state.entries, ...listResponse.entries],
              hasMore: listResponse.hasMore,
            },
        );
      })
      .catch(catchAbortError);
  }, [categoryStoreClient, loadMoreState, queryParams, queryState]);

  return (
    <div className={styles.root}>
      <div className={styles.querySection}>
        <ListQueryEditor
          schema={schema}
          value={selectors}
          onChange={setSelectors}
        />

        <Button
          className={styles.executeButton}
          intent="primary"
          large
          minimal
          outlined
          onClick={() => {
            setQueryParams({categorization, selectors});
          }}
        >
          Execute
        </Button>
      </div>

      <div className={styles.resultsSection}>
        {queryState.status === 'loading' && (
          <Delay>
            <Spinner />
          </Delay>
        )}

        {queryState.status === 'error' && (
          <NonIdealState
            icon="error"
            title="Error"
            description={
              queryState.error instanceof ClientError
                ? queryState.error.details
                : (queryState.error as any)?.message
            }
          />
        )}

        {queryState.status === 'done' && queryState.result != null && (
          <>
            {queryState.result.entries.length === 0 ? (
              <div>No entries</div>
            ) : (
              [
                ...queryState.result.entries,
                ...(loadMoreState?.entries ?? []),
              ].map((entry, i) => (
                <Callout key={i} className={styles.entry}>
                  <pre className={styles.entryAttributes}>
                    {entry.attributes
                      .map(attribute => `${attribute.key} = ${attribute.value}`)
                      .join('\n')}
                  </pre>
                  <Icon icon="arrow-right" />
                  <pre className={styles.entryResult}>{entry.result}</pre>
                </Callout>
              ))
            )}
            {(loadMoreState?.hasMore ?? queryState.result.hasMore) && (
              <Button
                minimal
                fill
                disabled={loadMoreState?.isLoading}
                text="Load more"
                onClick={handleLoadMoreClick}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
});

function createInitialSelectors(schema: Schema): AttributeSelectors {
  const selectors: AttributeSelectors = new Map();

  for (const attribute of schema.attributes) {
    selectors.set(attribute.key, emptyArray);
  }

  return selectors;
}
