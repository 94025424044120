import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Label,
  Switch,
  UL,
} from '@blueprintjs/core';
import React, {useState} from 'react';
import {removeAt} from 'typescript-immutable-utils';
import {stylesheet} from 'typestyle';
import {useCategoryStoreClient} from './AuthContext';
import {ClientError} from 'nice-grpc-web';
import {
  Schema,
  Schema_Attribute,
} from './proto/deeplay/category_store/v2/category_store';
import {toaster} from './toaster';
import {emptyArray} from './utils/empty';

export type CategorizationFormProps = {
  onCreate(entry: {categorization: string; schema: Schema}): void;
};

const styles = stylesheet({
  root: {
    width: 300,
    paddingTop: 60,
  },

  attributesLabel: {
    marginBottom: '-5px !important',
  },

  attribute: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  removeButton: {
    marginTop: -7,
  },

  newAttribute: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },

  implicitSwitch: {
    marginBottom: 0,
  },
});

export const CategorizationForm: React.FC<CategorizationFormProps> = props => {
  const {onCreate} = props;

  const categoryStoreClient = useCategoryStoreClient();

  const [categorization, setCategorization] = useState('');

  const [attributes, setAttributes] = useState<
    Array<{
      key: string;
      isImplicit: boolean;
    }>
  >(emptyArray);

  const [newAttributeKey, setNewAttributeKey] = useState('');
  const [newAttributeIsImplicit, setNewAttributeIsImplicit] = useState(false);

  return (
    <div className={styles.root}>
      <FormGroup label="Name">
        <InputGroup
          value={categorization}
          onChange={e => {
            setCategorization(e.currentTarget.value);
          }}
        />
      </FormGroup>

      <Label className={styles.attributesLabel}>Attributes</Label>

      <div className={Classes.RUNNING_TEXT}>
        <UL>
          {attributes.map((attribute, i) => (
            <li key={i}>
              <div className={styles.attribute}>
                <div>
                  <code>{attribute.key}</code>{' '}
                  {attribute.isImplicit && <em>implicit</em>}
                </div>
                {i === attributes.length - 1 && (
                  <Button
                    className={styles.removeButton}
                    minimal
                    icon="remove"
                    onClick={() => {
                      setAttributes(attributes => removeAt(attributes, i));
                    }}
                  />
                )}
              </div>
            </li>
          ))}
        </UL>
      </div>

      <div className={styles.newAttribute}>
        <InputGroup
          placeholder="Key"
          value={newAttributeKey}
          onChange={e => {
            setNewAttributeKey(e.currentTarget.value);
          }}
        />
        <Switch
          className={styles.implicitSwitch}
          alignIndicator="right"
          label="Implicit"
          checked={newAttributeIsImplicit}
          onChange={e => {
            setNewAttributeIsImplicit(e.currentTarget.checked);
          }}
        />
        <Button
          minimal
          icon="add"
          onClick={() => {
            if (!newAttributeKey) {
              return;
            }

            setAttributes(attributes => [
              ...attributes,
              {
                key: newAttributeKey,
                isImplicit: newAttributeIsImplicit,
              },
            ]);
            setNewAttributeKey('');
            setNewAttributeIsImplicit(false);
          }}
        />
      </div>

      <Button
        text="Create"
        intent="primary"
        large
        minimal
        outlined
        onClick={() => {
          const schema = Schema.fromPartial({
            attributes,
          });

          if (newAttributeKey) {
            schema.attributes.push(
              Schema_Attribute.fromPartial({
                key: newAttributeKey,
                isImplicit: newAttributeIsImplicit,
              }),
            );
          }

          categoryStoreClient.setSchema({categorization, schema}).then(
            () => {
              onCreate({categorization, schema});
            },
            error => {
              const errorText =
                error instanceof ClientError ? error.details : error?.message;

              toaster.show({
                message: `Failed to create categorization: ${errorText}`,
                intent: Intent.DANGER,
              });
            },
          );
        }}
      />
    </div>
  );
};
