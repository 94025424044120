import {memo} from 'react';

export type CategorizationNameProps = {
  categorization: string;
};

export const CategorizationName: React.FC<CategorizationNameProps> = memo(
  props => {
    const {categorization} = props;

    const parts = categorization.split('/');

    return (
      <span>
        {parts.map((part, i) => (
          <span key={i}>
            {part}
            {i !== parts.length - 1 && (
              <>
                /<wbr />
              </>
            )}
          </span>
        ))}
      </span>
    );
  },
);
