import {
  Alert,
  Button,
  Callout,
  Classes,
  H4,
  Intent,
  UL,
} from '@blueprintjs/core';
import React, {memo, useEffect, useState} from 'react';
import {setAt} from 'typescript-immutable-utils';
import {classes, stylesheet} from 'typestyle';
import {useCategoryStoreClient, useClient} from './AuthContext';
import {CategorizationName} from './CategorizationName';
import {DescriptionInput} from './DescriptionInput';
import {ClientError} from 'nice-grpc-web';
import {hasPermission} from './permissions';
import {
  DeepPartial,
  Schema,
  Schema_Attribute,
  Schema_Validator,
} from './proto/deeplay/category_store/v2/category_store';
import {toaster} from './toaster';
import {
  createValidatorValues,
  ValidatorInput,
  ValidatorValues,
} from './ValidatorInput';

export type SchemaPanelProps = {
  categorization: string;
  schema: Schema;
  onSave(entry: {categorization: string; schema: Schema}): void;
  onDelete(categorization: string): void;
};

const styles = stylesheet({
  schemaInfo: {
    display: 'flex',
    marginBottom: 15,
  },

  leftColumn: {
    flex: '1 1 0',
    paddingRight: 10,
  },
  rightColumn: {
    flex: '1 1 0',
    paddingLeft: 10,
  },

  firstHeader: {
    marginTop: '0px !important',
  },

  attributeKey: {
    fontSize: '14px !important',
  },

  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const SchemaPanel: React.FC<SchemaPanelProps> = memo(props => {
  const {categorization, schema, onSave, onDelete} = props;

  const client = useClient();
  const categoryStoreClient = useCategoryStoreClient();

  const hasManagePermission = hasPermission(client, categorization, 'manage');

  const [schemaValues, setSchemaValues] = useState(() =>
    createSchemaValues(schema),
  );

  useEffect(() => {
    setSchemaValues(createSchemaValues(schema));
  }, [schema]);

  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  return (
    <div>
      <div className={classes(styles.schemaInfo, Classes.RUNNING_TEXT)}>
        <div className={styles.leftColumn}>
          <H4 className={styles.firstHeader}>Categorization</H4>
          <Callout className={Classes.MONOSPACE_TEXT}>
            <CategorizationName categorization={categorization} />
          </Callout>

          <H4 className={styles.firstHeader}>Attributes</H4>
          <UL>
            {schemaValues.attributes.map((attributeValues, i) => (
              <li key={i}>
                <div>
                  <code className={styles.attributeKey}>
                    {attributeValues.key}
                  </code>{' '}
                  {attributeValues.isImplicit && <em>implicit</em>}
                </div>
                <DescriptionInput
                  editable={hasManagePermission}
                  small
                  value={attributeValues.description}
                  onChange={e => {
                    const description = e.currentTarget.value;
                    setSchemaValues(values => ({
                      ...values,
                      attributes: setAt(values.attributes, i, {
                        ...attributeValues,
                        description,
                      }),
                    }));
                  }}
                />
                <ValidatorInput
                  editable={hasManagePermission}
                  validator={attributeValues.validator}
                  onChange={validator => {
                    setSchemaValues(values => ({
                      ...values,
                      attributes: setAt(values.attributes, i, {
                        ...attributeValues,
                        validator,
                      }),
                    }));
                  }}
                />
              </li>
            ))}
          </UL>
        </div>

        <div className={styles.rightColumn}>
          <H4 className={styles.firstHeader}>Description</H4>
          <DescriptionInput
            editable={hasManagePermission}
            value={schemaValues.description}
            onChange={e => {
              const description = e.currentTarget.value;

              setSchemaValues(values => ({
                ...values,
                description,
              }));
            }}
          />

          <H4>Result</H4>
          <ValidatorInput
            editable={hasManagePermission}
            validator={schemaValues.resultValidator}
            onChange={validator => {
              setSchemaValues(values => ({
                ...values,
                resultValidator: validator,
              }));
            }}
          />
        </div>
      </div>

      {hasManagePermission && (
        <div className={styles.buttons}>
          <Button
            text="Save"
            intent="primary"
            large
            minimal
            outlined
            onClick={() => {
              const schema = createSchemaFromValues(schemaValues);

              categoryStoreClient.setSchema({categorization, schema}).then(
                () => {
                  toaster.show({
                    message: 'Schema saved',
                    intent: Intent.SUCCESS,
                  });

                  onSave({categorization, schema});
                },
                error => {
                  const errorText =
                    error instanceof ClientError
                      ? error.details
                      : error?.message;

                  toaster.show({
                    message: `Failed to save schema: ${errorText}`,
                    intent: Intent.DANGER,
                  });
                },
              );
            }}
          />

          <Button
            text="Delete"
            intent="danger"
            large
            minimal
            outlined
            onClick={() => {
              setIsDeleteAlertOpen(true);
            }}
          />
          <Alert
            canEscapeKeyCancel
            canOutsideClickCancel
            cancelButtonText="Cancel"
            confirmButtonText="Delete"
            isOpen={isDeleteAlertOpen}
            intent="danger"
            icon="trash"
            onCancel={() => {
              setIsDeleteAlertOpen(false);
            }}
            onConfirm={() => {
              categoryStoreClient.deleteSchema({categorization}).then(
                () => {
                  toaster.show({
                    message: 'Schema deleted',
                    intent: Intent.SUCCESS,
                  });

                  onDelete(categorization);
                  setIsDeleteAlertOpen(false);
                },
                error => {
                  const errorText =
                    error instanceof ClientError
                      ? error.details
                      : error?.message;

                  toaster.show({
                    message: `Failed to delete schema: ${errorText}`,
                    intent: Intent.DANGER,
                  });
                },
              );
            }}
          >
            <p>
              Are you sure you want to delete schema? All categorization data
              will be deleted.
            </p>
          </Alert>
        </div>
      )}
    </div>
  );
});

type SchemaValues = {
  description: string;
  attributes: Array<{
    key: string;
    description: string;
    validator: ValidatorValues;
    isImplicit: boolean;
  }>;
  resultValidator: ValidatorValues;
};

function createSchemaValues(schema: Schema): SchemaValues {
  return {
    description: schema.description,
    attributes: schema.attributes.map(attribute => ({
      key: attribute.key,
      description: attribute.description,
      validator: createValidatorValues(attribute.validator),
      isImplicit: attribute.isImplicit,
    })),
    resultValidator: createValidatorValues(schema.resultValidator),
  };
}

function createSchemaFromValues(values: SchemaValues): Schema {
  return Schema.fromPartial({
    description: values.description,
    attributes: values.attributes.map(
      (attributeValues): DeepPartial<Schema_Attribute> => ({
        key: attributeValues.key,
        description: attributeValues.description,
        validator: createValidatorFromValues(attributeValues.validator),
        isImplicit: attributeValues.isImplicit,
      }),
    ),
    resultValidator: createValidatorFromValues(values.resultValidator),
  });
}

function createValidatorFromValues(
  values: ValidatorValues,
): Schema_Validator | undefined {
  const {enum: elements, jsonschema, regexp} = values;
  if (values.type === 'regexp') {
    return Schema_Validator.fromPartial({
      validator: {$case: 'regexp', regexp},
    });
  } else if (values.type === 'jsonschema') {
    return Schema_Validator.fromPartial({
      validator: {$case: 'jsonschema', jsonschema},
    });
  } else if (values.type === 'enum') {
    return Schema_Validator.fromPartial({
      validator: {$case: 'enum', enum: {elements}},
    });
  }

  return undefined;
}
