import {ReactNode, useEffect, useState} from 'react';

export type DelayProps = {
  delayMs?: number;
  children: ReactNode;
};

export const Delay = (props: DelayProps) => {
  const {delayMs = 1000, children} = props;

  const [passed, setPassed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPassed(true);
    }, delayMs);

    return () => {
      clearTimeout(timer);
    };
  }, [delayMs]);

  return <>{passed && children}</>;
};
