/* eslint-disable */
import { messageTypeRegistry } from "../../../typeRegistry";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { StringValue } from "../../../google/protobuf/wrappers";

export const protobufPackage = "deeplay.category_store.v2";

export interface Attribute {
  $type: "deeplay.category_store.v2.Attribute";
  key: string;
  value: string;
}

export interface AttributeSelector {
  $type: "deeplay.category_store.v2.AttributeSelector";
  key: string;
  valueIn: string[];
}

export interface GetRequest {
  $type: "deeplay.category_store.v2.GetRequest";
  categorization: string;
  /**
   * Набор селекторов.
   *
   * Должен содержать по селектору для каждого explicit атрибута, и опционально
   * — для implicit атрибутов.
   */
  selectors: AttributeSelector[];
}

export interface GetResponse {
  $type: "deeplay.category_store.v2.GetResponse";
  entries: GetResponse_Entry[];
}

export interface GetResponse_Entry {
  $type: "deeplay.category_store.v2.GetResponse.Entry";
  attributes: Attribute[];
  result: string;
}

export interface ListRequest {
  $type: "deeplay.category_store.v2.ListRequest";
  categorization: string;
  /**
   * Набор селекторов для очистки.
   *
   * Должен содержать по селектору для каждого атрибута. Пустой массив
   * `value_in` в селекторе означает *, т.е. будут возвращены записи с любым
   * значением этого атрибута.
   */
  selectors: AttributeSelector[];
  /**
   * Если определено, будут возвращены записи, следующие за записью с этими
   * атрибутами. Используется для пагинации.
   */
  after: Attribute[];
  /** Будет возвращено не более, чем это количество записей. */
  limit: number;
}

export interface ListResponse {
  $type: "deeplay.category_store.v2.ListResponse";
  entries: ListResponse_Entry[];
  hasMore: boolean;
}

export interface ListResponse_Entry {
  $type: "deeplay.category_store.v2.ListResponse.Entry";
  attributes: Attribute[];
  result: string;
}

export interface SetRequest {
  $type: "deeplay.category_store.v2.SetRequest";
  categorization: string;
  entries: SetRequest_Entry[];
}

export interface SetRequest_Entry {
  $type: "deeplay.category_store.v2.SetRequest.Entry";
  /**
   * Набор атрибутов записи.
   *
   * Должен содержать все атрибуты.
   */
  attributes: Attribute[];
  /** Если отсутствует — запись категоризации будет удалена. */
  result: string | undefined;
}

export interface SetResponse {
  $type: "deeplay.category_store.v2.SetResponse";
}

export interface ReplaceRequest {
  $type: "deeplay.category_store.v2.ReplaceRequest";
  categorization: string;
  /**
   * Набор селекторов для очистки.
   *
   * Должен содержать по селектору для каждого атрибута. Пустой массив
   * `value_in` в селекторе означает *, т.е. будут очищены записи с любым
   * значением этого атрибута.
   */
  selectors: AttributeSelector[];
  /** Список новых записей. */
  entries: ReplaceRequest_Entry[];
}

export interface ReplaceRequest_Entry {
  $type: "deeplay.category_store.v2.ReplaceRequest.Entry";
  /**
   * Набор атрибутов записи.
   *
   * Должен содержать все атрибуты.
   */
  attributes: Attribute[];
  result: string;
}

export interface ReplaceResponse {
  $type: "deeplay.category_store.v2.ReplaceResponse";
}

export interface Rule {
  $type: "deeplay.category_store.v2.Rule";
  /**
   * Набор селекторов для правила.
   *
   * Должен содержать по селектору для каждого explicit атрибута.
   *
   * Отсутствие селектора для implicit атрибута означает дырку, т.е. правило
   * будет срабатывать на Get запросах, в которых этот атрибут не указан.
   *
   * Пустой массив `value_in` в селекторе означает *, т.е. правило будет
   * срабатывать на Get запросах с любым значением этого атрибута.
   */
  fromSelectors: AttributeSelector[];
  /**
   * Набор implicit атрибутов, подставляемых правилом.
   *
   * Каждый подставляемый атрибут должен соответствовать дырке в
   * `from_selectors`, но не для каждой дырки обязан быть подставляемый атрибут.
   */
  toAttributes: Attribute[];
}

export interface SetRulesRequest {
  $type: "deeplay.category_store.v2.SetRulesRequest";
  categorization: string;
  /** Упорядоченный набор всех правил для категоризации. */
  rules: Rule[];
}

export interface SetRulesResponse {
  $type: "deeplay.category_store.v2.SetRulesResponse";
}

export interface GetRulesRequest {
  $type: "deeplay.category_store.v2.GetRulesRequest";
  categorization: string;
  /**
   * Если непусто — возвращает только правила, действующие на этом наборе
   * атрибутов. Набор должен содержать все explicit атрибуты и опционально —
   * implicit атрибуты.
   */
  forAttributes: Attribute[];
}

export interface GetRulesResponse {
  $type: "deeplay.category_store.v2.GetRulesResponse";
  rules: Rule[];
}

export interface Schema {
  $type: "deeplay.category_store.v2.Schema";
  /** Описание категоризации и её схемы. */
  description: string;
  /**
   * Список атрибутов для записей категоризации.
   *
   * Набор ключей атрибутов в схеме не может быть изменён после создания.
   */
  attributes: Schema_Attribute[];
  /** Валидатор для результата в записях категоризации. */
  resultValidator: Schema_Validator | undefined;
}

export interface Schema_Attribute {
  $type: "deeplay.category_store.v2.Schema.Attribute";
  /** Ключ атрибута. */
  key: string;
  /** Описание атрибута. */
  description: string;
  /** Валидатор для значения атрибута. */
  validator: Schema_Validator | undefined;
  /**
   * Если `true`, то при Get запросе селектор на этот атрибут можно не
   * указывать: в этом случае значение подставляется на основании правил.
   */
  isImplicit: boolean;
}

/** Валидатор для строковых значений. */
export interface Schema_Validator {
  $type: "deeplay.category_store.v2.Schema.Validator";
  validator?:
    | { $case: "enum"; enum: Schema_Validator_Enum }
    | { $case: "regexp"; regexp: string }
    | { $case: "jsonschema"; jsonschema: string };
}

export interface Schema_Validator_Enum {
  $type: "deeplay.category_store.v2.Schema.Validator.Enum";
  elements: Schema_Validator_Enum_Element[];
}

export interface Schema_Validator_Enum_Element {
  $type: "deeplay.category_store.v2.Schema.Validator.Enum.Element";
  value: string;
  description: string;
}

export interface SetSchemaRequest {
  $type: "deeplay.category_store.v2.SetSchemaRequest";
  categorization: string;
  schema: Schema | undefined;
}

export interface SetSchemaResponse {
  $type: "deeplay.category_store.v2.SetSchemaResponse";
}

export interface GetSchemasRequest {
  $type: "deeplay.category_store.v2.GetSchemasRequest";
}

export interface GetSchemasResponse {
  $type: "deeplay.category_store.v2.GetSchemasResponse";
  entries: GetSchemasResponse_SchemaEntry[];
}

export interface GetSchemasResponse_SchemaEntry {
  $type: "deeplay.category_store.v2.GetSchemasResponse.SchemaEntry";
  categorization: string;
  schema: Schema | undefined;
}

export interface DeleteSchemaRequest {
  $type: "deeplay.category_store.v2.DeleteSchemaRequest";
  categorization: string;
}

export interface DeleteSchemaResponse {
  $type: "deeplay.category_store.v2.DeleteSchemaResponse";
}

const baseAttribute: object = {
  $type: "deeplay.category_store.v2.Attribute",
  key: "",
  value: "",
};

export const Attribute = {
  $type: "deeplay.category_store.v2.Attribute" as const,

  encode(
    message: Attribute,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Attribute {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAttribute } as Attribute;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Attribute>): Attribute {
    const message = { ...baseAttribute } as Attribute;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    }
    return message;
  },
};

messageTypeRegistry.set(Attribute.$type, Attribute);

const baseAttributeSelector: object = {
  $type: "deeplay.category_store.v2.AttributeSelector",
  key: "",
  valueIn: "",
};

export const AttributeSelector = {
  $type: "deeplay.category_store.v2.AttributeSelector" as const,

  encode(
    message: AttributeSelector,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    for (const v of message.valueIn) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttributeSelector {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAttributeSelector } as AttributeSelector;
    message.valueIn = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.valueIn.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<AttributeSelector>): AttributeSelector {
    const message = { ...baseAttributeSelector } as AttributeSelector;
    message.valueIn = [];
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    }
    if (object.valueIn !== undefined && object.valueIn !== null) {
      for (const e of object.valueIn) {
        message.valueIn.push(e);
      }
    }
    return message;
  },
};

messageTypeRegistry.set(AttributeSelector.$type, AttributeSelector);

const baseGetRequest: object = {
  $type: "deeplay.category_store.v2.GetRequest",
  categorization: "",
};

export const GetRequest = {
  $type: "deeplay.category_store.v2.GetRequest" as const,

  encode(
    message: GetRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.categorization !== "") {
      writer.uint32(10).string(message.categorization);
    }
    for (const v of message.selectors) {
      AttributeSelector.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetRequest } as GetRequest;
    message.selectors = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categorization = reader.string();
          break;
        case 2:
          message.selectors.push(
            AttributeSelector.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetRequest>): GetRequest {
    const message = { ...baseGetRequest } as GetRequest;
    message.selectors = [];
    if (object.categorization !== undefined && object.categorization !== null) {
      message.categorization = object.categorization;
    }
    if (object.selectors !== undefined && object.selectors !== null) {
      for (const e of object.selectors) {
        message.selectors.push(AttributeSelector.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(GetRequest.$type, GetRequest);

const baseGetResponse: object = {
  $type: "deeplay.category_store.v2.GetResponse",
};

export const GetResponse = {
  $type: "deeplay.category_store.v2.GetResponse" as const,

  encode(
    message: GetResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.entries) {
      GetResponse_Entry.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetResponse } as GetResponse;
    message.entries = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.entries.push(
            GetResponse_Entry.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetResponse>): GetResponse {
    const message = { ...baseGetResponse } as GetResponse;
    message.entries = [];
    if (object.entries !== undefined && object.entries !== null) {
      for (const e of object.entries) {
        message.entries.push(GetResponse_Entry.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(GetResponse.$type, GetResponse);

const baseGetResponse_Entry: object = {
  $type: "deeplay.category_store.v2.GetResponse.Entry",
  result: "",
};

export const GetResponse_Entry = {
  $type: "deeplay.category_store.v2.GetResponse.Entry" as const,

  encode(
    message: GetResponse_Entry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.attributes) {
      Attribute.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.result !== "") {
      writer.uint32(18).string(message.result);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetResponse_Entry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetResponse_Entry } as GetResponse_Entry;
    message.attributes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.attributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        case 2:
          message.result = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetResponse_Entry>): GetResponse_Entry {
    const message = { ...baseGetResponse_Entry } as GetResponse_Entry;
    message.attributes = [];
    if (object.attributes !== undefined && object.attributes !== null) {
      for (const e of object.attributes) {
        message.attributes.push(Attribute.fromPartial(e));
      }
    }
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    return message;
  },
};

messageTypeRegistry.set(GetResponse_Entry.$type, GetResponse_Entry);

const baseListRequest: object = {
  $type: "deeplay.category_store.v2.ListRequest",
  categorization: "",
  limit: 0,
};

export const ListRequest = {
  $type: "deeplay.category_store.v2.ListRequest" as const,

  encode(
    message: ListRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.categorization !== "") {
      writer.uint32(10).string(message.categorization);
    }
    for (const v of message.selectors) {
      AttributeSelector.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.after) {
      Attribute.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.limit !== 0) {
      writer.uint32(32).uint32(message.limit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListRequest } as ListRequest;
    message.selectors = [];
    message.after = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categorization = reader.string();
          break;
        case 2:
          message.selectors.push(
            AttributeSelector.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.after.push(Attribute.decode(reader, reader.uint32()));
          break;
        case 4:
          message.limit = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ListRequest>): ListRequest {
    const message = { ...baseListRequest } as ListRequest;
    message.selectors = [];
    message.after = [];
    if (object.categorization !== undefined && object.categorization !== null) {
      message.categorization = object.categorization;
    }
    if (object.selectors !== undefined && object.selectors !== null) {
      for (const e of object.selectors) {
        message.selectors.push(AttributeSelector.fromPartial(e));
      }
    }
    if (object.after !== undefined && object.after !== null) {
      for (const e of object.after) {
        message.after.push(Attribute.fromPartial(e));
      }
    }
    if (object.limit !== undefined && object.limit !== null) {
      message.limit = object.limit;
    }
    return message;
  },
};

messageTypeRegistry.set(ListRequest.$type, ListRequest);

const baseListResponse: object = {
  $type: "deeplay.category_store.v2.ListResponse",
  hasMore: false,
};

export const ListResponse = {
  $type: "deeplay.category_store.v2.ListResponse" as const,

  encode(
    message: ListResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.entries) {
      ListResponse_Entry.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.hasMore === true) {
      writer.uint32(16).bool(message.hasMore);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListResponse } as ListResponse;
    message.entries = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.entries.push(
            ListResponse_Entry.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.hasMore = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ListResponse>): ListResponse {
    const message = { ...baseListResponse } as ListResponse;
    message.entries = [];
    if (object.entries !== undefined && object.entries !== null) {
      for (const e of object.entries) {
        message.entries.push(ListResponse_Entry.fromPartial(e));
      }
    }
    if (object.hasMore !== undefined && object.hasMore !== null) {
      message.hasMore = object.hasMore;
    }
    return message;
  },
};

messageTypeRegistry.set(ListResponse.$type, ListResponse);

const baseListResponse_Entry: object = {
  $type: "deeplay.category_store.v2.ListResponse.Entry",
  result: "",
};

export const ListResponse_Entry = {
  $type: "deeplay.category_store.v2.ListResponse.Entry" as const,

  encode(
    message: ListResponse_Entry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.attributes) {
      Attribute.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.result !== "") {
      writer.uint32(18).string(message.result);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListResponse_Entry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseListResponse_Entry } as ListResponse_Entry;
    message.attributes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.attributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        case 2:
          message.result = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ListResponse_Entry>): ListResponse_Entry {
    const message = { ...baseListResponse_Entry } as ListResponse_Entry;
    message.attributes = [];
    if (object.attributes !== undefined && object.attributes !== null) {
      for (const e of object.attributes) {
        message.attributes.push(Attribute.fromPartial(e));
      }
    }
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    return message;
  },
};

messageTypeRegistry.set(ListResponse_Entry.$type, ListResponse_Entry);

const baseSetRequest: object = {
  $type: "deeplay.category_store.v2.SetRequest",
  categorization: "",
};

export const SetRequest = {
  $type: "deeplay.category_store.v2.SetRequest" as const,

  encode(
    message: SetRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.categorization !== "") {
      writer.uint32(10).string(message.categorization);
    }
    for (const v of message.entries) {
      SetRequest_Entry.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetRequest } as SetRequest;
    message.entries = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categorization = reader.string();
          break;
        case 2:
          message.entries.push(
            SetRequest_Entry.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SetRequest>): SetRequest {
    const message = { ...baseSetRequest } as SetRequest;
    message.entries = [];
    if (object.categorization !== undefined && object.categorization !== null) {
      message.categorization = object.categorization;
    }
    if (object.entries !== undefined && object.entries !== null) {
      for (const e of object.entries) {
        message.entries.push(SetRequest_Entry.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(SetRequest.$type, SetRequest);

const baseSetRequest_Entry: object = {
  $type: "deeplay.category_store.v2.SetRequest.Entry",
};

export const SetRequest_Entry = {
  $type: "deeplay.category_store.v2.SetRequest.Entry" as const,

  encode(
    message: SetRequest_Entry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.attributes) {
      Attribute.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.result !== undefined) {
      StringValue.encode(
        { $type: "google.protobuf.StringValue", value: message.result! },
        writer.uint32(18).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetRequest_Entry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetRequest_Entry } as SetRequest_Entry;
    message.attributes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.attributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        case 2:
          message.result = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SetRequest_Entry>): SetRequest_Entry {
    const message = { ...baseSetRequest_Entry } as SetRequest_Entry;
    message.attributes = [];
    if (object.attributes !== undefined && object.attributes !== null) {
      for (const e of object.attributes) {
        message.attributes.push(Attribute.fromPartial(e));
      }
    }
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    return message;
  },
};

messageTypeRegistry.set(SetRequest_Entry.$type, SetRequest_Entry);

const baseSetResponse: object = {
  $type: "deeplay.category_store.v2.SetResponse",
};

export const SetResponse = {
  $type: "deeplay.category_store.v2.SetResponse" as const,

  encode(_: SetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetResponse } as SetResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<SetResponse>): SetResponse {
    const message = { ...baseSetResponse } as SetResponse;
    return message;
  },
};

messageTypeRegistry.set(SetResponse.$type, SetResponse);

const baseReplaceRequest: object = {
  $type: "deeplay.category_store.v2.ReplaceRequest",
  categorization: "",
};

export const ReplaceRequest = {
  $type: "deeplay.category_store.v2.ReplaceRequest" as const,

  encode(
    message: ReplaceRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.categorization !== "") {
      writer.uint32(10).string(message.categorization);
    }
    for (const v of message.selectors) {
      AttributeSelector.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.entries) {
      ReplaceRequest_Entry.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReplaceRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseReplaceRequest } as ReplaceRequest;
    message.selectors = [];
    message.entries = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categorization = reader.string();
          break;
        case 2:
          message.selectors.push(
            AttributeSelector.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.entries.push(
            ReplaceRequest_Entry.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ReplaceRequest>): ReplaceRequest {
    const message = { ...baseReplaceRequest } as ReplaceRequest;
    message.selectors = [];
    message.entries = [];
    if (object.categorization !== undefined && object.categorization !== null) {
      message.categorization = object.categorization;
    }
    if (object.selectors !== undefined && object.selectors !== null) {
      for (const e of object.selectors) {
        message.selectors.push(AttributeSelector.fromPartial(e));
      }
    }
    if (object.entries !== undefined && object.entries !== null) {
      for (const e of object.entries) {
        message.entries.push(ReplaceRequest_Entry.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(ReplaceRequest.$type, ReplaceRequest);

const baseReplaceRequest_Entry: object = {
  $type: "deeplay.category_store.v2.ReplaceRequest.Entry",
  result: "",
};

export const ReplaceRequest_Entry = {
  $type: "deeplay.category_store.v2.ReplaceRequest.Entry" as const,

  encode(
    message: ReplaceRequest_Entry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.attributes) {
      Attribute.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.result !== "") {
      writer.uint32(18).string(message.result);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ReplaceRequest_Entry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseReplaceRequest_Entry } as ReplaceRequest_Entry;
    message.attributes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.attributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        case 2:
          message.result = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<ReplaceRequest_Entry>): ReplaceRequest_Entry {
    const message = { ...baseReplaceRequest_Entry } as ReplaceRequest_Entry;
    message.attributes = [];
    if (object.attributes !== undefined && object.attributes !== null) {
      for (const e of object.attributes) {
        message.attributes.push(Attribute.fromPartial(e));
      }
    }
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    }
    return message;
  },
};

messageTypeRegistry.set(ReplaceRequest_Entry.$type, ReplaceRequest_Entry);

const baseReplaceResponse: object = {
  $type: "deeplay.category_store.v2.ReplaceResponse",
};

export const ReplaceResponse = {
  $type: "deeplay.category_store.v2.ReplaceResponse" as const,

  encode(
    _: ReplaceResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReplaceResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseReplaceResponse } as ReplaceResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<ReplaceResponse>): ReplaceResponse {
    const message = { ...baseReplaceResponse } as ReplaceResponse;
    return message;
  },
};

messageTypeRegistry.set(ReplaceResponse.$type, ReplaceResponse);

const baseRule: object = { $type: "deeplay.category_store.v2.Rule" };

export const Rule = {
  $type: "deeplay.category_store.v2.Rule" as const,

  encode(message: Rule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fromSelectors) {
      AttributeSelector.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.toAttributes) {
      Attribute.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Rule {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseRule } as Rule;
    message.fromSelectors = [];
    message.toAttributes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.fromSelectors.push(
            AttributeSelector.decode(reader, reader.uint32())
          );
          break;
        case 2:
          message.toAttributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Rule>): Rule {
    const message = { ...baseRule } as Rule;
    message.fromSelectors = [];
    message.toAttributes = [];
    if (object.fromSelectors !== undefined && object.fromSelectors !== null) {
      for (const e of object.fromSelectors) {
        message.fromSelectors.push(AttributeSelector.fromPartial(e));
      }
    }
    if (object.toAttributes !== undefined && object.toAttributes !== null) {
      for (const e of object.toAttributes) {
        message.toAttributes.push(Attribute.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(Rule.$type, Rule);

const baseSetRulesRequest: object = {
  $type: "deeplay.category_store.v2.SetRulesRequest",
  categorization: "",
};

export const SetRulesRequest = {
  $type: "deeplay.category_store.v2.SetRulesRequest" as const,

  encode(
    message: SetRulesRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.categorization !== "") {
      writer.uint32(10).string(message.categorization);
    }
    for (const v of message.rules) {
      Rule.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetRulesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetRulesRequest } as SetRulesRequest;
    message.rules = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categorization = reader.string();
          break;
        case 2:
          message.rules.push(Rule.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SetRulesRequest>): SetRulesRequest {
    const message = { ...baseSetRulesRequest } as SetRulesRequest;
    message.rules = [];
    if (object.categorization !== undefined && object.categorization !== null) {
      message.categorization = object.categorization;
    }
    if (object.rules !== undefined && object.rules !== null) {
      for (const e of object.rules) {
        message.rules.push(Rule.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(SetRulesRequest.$type, SetRulesRequest);

const baseSetRulesResponse: object = {
  $type: "deeplay.category_store.v2.SetRulesResponse",
};

export const SetRulesResponse = {
  $type: "deeplay.category_store.v2.SetRulesResponse" as const,

  encode(
    _: SetRulesResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetRulesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetRulesResponse } as SetRulesResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<SetRulesResponse>): SetRulesResponse {
    const message = { ...baseSetRulesResponse } as SetRulesResponse;
    return message;
  },
};

messageTypeRegistry.set(SetRulesResponse.$type, SetRulesResponse);

const baseGetRulesRequest: object = {
  $type: "deeplay.category_store.v2.GetRulesRequest",
  categorization: "",
};

export const GetRulesRequest = {
  $type: "deeplay.category_store.v2.GetRulesRequest" as const,

  encode(
    message: GetRulesRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.categorization !== "") {
      writer.uint32(10).string(message.categorization);
    }
    for (const v of message.forAttributes) {
      Attribute.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRulesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetRulesRequest } as GetRulesRequest;
    message.forAttributes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categorization = reader.string();
          break;
        case 2:
          message.forAttributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetRulesRequest>): GetRulesRequest {
    const message = { ...baseGetRulesRequest } as GetRulesRequest;
    message.forAttributes = [];
    if (object.categorization !== undefined && object.categorization !== null) {
      message.categorization = object.categorization;
    }
    if (object.forAttributes !== undefined && object.forAttributes !== null) {
      for (const e of object.forAttributes) {
        message.forAttributes.push(Attribute.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(GetRulesRequest.$type, GetRulesRequest);

const baseGetRulesResponse: object = {
  $type: "deeplay.category_store.v2.GetRulesResponse",
};

export const GetRulesResponse = {
  $type: "deeplay.category_store.v2.GetRulesResponse" as const,

  encode(
    message: GetRulesResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.rules) {
      Rule.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRulesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetRulesResponse } as GetRulesResponse;
    message.rules = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.rules.push(Rule.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetRulesResponse>): GetRulesResponse {
    const message = { ...baseGetRulesResponse } as GetRulesResponse;
    message.rules = [];
    if (object.rules !== undefined && object.rules !== null) {
      for (const e of object.rules) {
        message.rules.push(Rule.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(GetRulesResponse.$type, GetRulesResponse);

const baseSchema: object = {
  $type: "deeplay.category_store.v2.Schema",
  description: "",
};

export const Schema = {
  $type: "deeplay.category_store.v2.Schema" as const,

  encode(
    message: Schema,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    for (const v of message.attributes) {
      Schema_Attribute.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.resultValidator !== undefined) {
      Schema_Validator.encode(
        message.resultValidator,
        writer.uint32(26).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Schema {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSchema } as Schema;
    message.attributes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.description = reader.string();
          break;
        case 2:
          message.attributes.push(
            Schema_Attribute.decode(reader, reader.uint32())
          );
          break;
        case 3:
          message.resultValidator = Schema_Validator.decode(
            reader,
            reader.uint32()
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Schema>): Schema {
    const message = { ...baseSchema } as Schema;
    message.attributes = [];
    if (object.description !== undefined && object.description !== null) {
      message.description = object.description;
    }
    if (object.attributes !== undefined && object.attributes !== null) {
      for (const e of object.attributes) {
        message.attributes.push(Schema_Attribute.fromPartial(e));
      }
    }
    if (
      object.resultValidator !== undefined &&
      object.resultValidator !== null
    ) {
      message.resultValidator = Schema_Validator.fromPartial(
        object.resultValidator
      );
    }
    return message;
  },
};

messageTypeRegistry.set(Schema.$type, Schema);

const baseSchema_Attribute: object = {
  $type: "deeplay.category_store.v2.Schema.Attribute",
  key: "",
  description: "",
  isImplicit: false,
};

export const Schema_Attribute = {
  $type: "deeplay.category_store.v2.Schema.Attribute" as const,

  encode(
    message: Schema_Attribute,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.validator !== undefined) {
      Schema_Validator.encode(
        message.validator,
        writer.uint32(26).fork()
      ).ldelim();
    }
    if (message.isImplicit === true) {
      writer.uint32(32).bool(message.isImplicit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Schema_Attribute {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSchema_Attribute } as Schema_Attribute;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.description = reader.string();
          break;
        case 3:
          message.validator = Schema_Validator.decode(reader, reader.uint32());
          break;
        case 4:
          message.isImplicit = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Schema_Attribute>): Schema_Attribute {
    const message = { ...baseSchema_Attribute } as Schema_Attribute;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = object.description;
    }
    if (object.validator !== undefined && object.validator !== null) {
      message.validator = Schema_Validator.fromPartial(object.validator);
    }
    if (object.isImplicit !== undefined && object.isImplicit !== null) {
      message.isImplicit = object.isImplicit;
    }
    return message;
  },
};

messageTypeRegistry.set(Schema_Attribute.$type, Schema_Attribute);

const baseSchema_Validator: object = {
  $type: "deeplay.category_store.v2.Schema.Validator",
};

export const Schema_Validator = {
  $type: "deeplay.category_store.v2.Schema.Validator" as const,

  encode(
    message: Schema_Validator,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.validator?.$case === "enum") {
      Schema_Validator_Enum.encode(
        message.validator.enum,
        writer.uint32(10).fork()
      ).ldelim();
    }
    if (message.validator?.$case === "regexp") {
      writer.uint32(18).string(message.validator.regexp);
    }
    if (message.validator?.$case === "jsonschema") {
      writer.uint32(26).string(message.validator.jsonschema);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Schema_Validator {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSchema_Validator } as Schema_Validator;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.validator = {
            $case: "enum",
            enum: Schema_Validator_Enum.decode(reader, reader.uint32()),
          };
          break;
        case 2:
          message.validator = { $case: "regexp", regexp: reader.string() };
          break;
        case 3:
          message.validator = {
            $case: "jsonschema",
            jsonschema: reader.string(),
          };
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<Schema_Validator>): Schema_Validator {
    const message = { ...baseSchema_Validator } as Schema_Validator;
    if (
      object.validator?.$case === "enum" &&
      object.validator?.enum !== undefined &&
      object.validator?.enum !== null
    ) {
      message.validator = {
        $case: "enum",
        enum: Schema_Validator_Enum.fromPartial(object.validator.enum),
      };
    }
    if (
      object.validator?.$case === "regexp" &&
      object.validator?.regexp !== undefined &&
      object.validator?.regexp !== null
    ) {
      message.validator = { $case: "regexp", regexp: object.validator.regexp };
    }
    if (
      object.validator?.$case === "jsonschema" &&
      object.validator?.jsonschema !== undefined &&
      object.validator?.jsonschema !== null
    ) {
      message.validator = {
        $case: "jsonschema",
        jsonschema: object.validator.jsonschema,
      };
    }
    return message;
  },
};

messageTypeRegistry.set(Schema_Validator.$type, Schema_Validator);

const baseSchema_Validator_Enum: object = {
  $type: "deeplay.category_store.v2.Schema.Validator.Enum",
};

export const Schema_Validator_Enum = {
  $type: "deeplay.category_store.v2.Schema.Validator.Enum" as const,

  encode(
    message: Schema_Validator_Enum,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.elements) {
      Schema_Validator_Enum_Element.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Schema_Validator_Enum {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSchema_Validator_Enum } as Schema_Validator_Enum;
    message.elements = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.elements.push(
            Schema_Validator_Enum_Element.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<Schema_Validator_Enum>
  ): Schema_Validator_Enum {
    const message = { ...baseSchema_Validator_Enum } as Schema_Validator_Enum;
    message.elements = [];
    if (object.elements !== undefined && object.elements !== null) {
      for (const e of object.elements) {
        message.elements.push(Schema_Validator_Enum_Element.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(Schema_Validator_Enum.$type, Schema_Validator_Enum);

const baseSchema_Validator_Enum_Element: object = {
  $type: "deeplay.category_store.v2.Schema.Validator.Enum.Element",
  value: "",
  description: "",
};

export const Schema_Validator_Enum_Element = {
  $type: "deeplay.category_store.v2.Schema.Validator.Enum.Element" as const,

  encode(
    message: Schema_Validator_Enum_Element,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Schema_Validator_Enum_Element {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseSchema_Validator_Enum_Element,
    } as Schema_Validator_Enum_Element;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.value = reader.string();
          break;
        case 2:
          message.description = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<Schema_Validator_Enum_Element>
  ): Schema_Validator_Enum_Element {
    const message = {
      ...baseSchema_Validator_Enum_Element,
    } as Schema_Validator_Enum_Element;
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = object.description;
    }
    return message;
  },
};

messageTypeRegistry.set(
  Schema_Validator_Enum_Element.$type,
  Schema_Validator_Enum_Element
);

const baseSetSchemaRequest: object = {
  $type: "deeplay.category_store.v2.SetSchemaRequest",
  categorization: "",
};

export const SetSchemaRequest = {
  $type: "deeplay.category_store.v2.SetSchemaRequest" as const,

  encode(
    message: SetSchemaRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.categorization !== "") {
      writer.uint32(10).string(message.categorization);
    }
    if (message.schema !== undefined) {
      Schema.encode(message.schema, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSchemaRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetSchemaRequest } as SetSchemaRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categorization = reader.string();
          break;
        case 2:
          message.schema = Schema.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<SetSchemaRequest>): SetSchemaRequest {
    const message = { ...baseSetSchemaRequest } as SetSchemaRequest;
    if (object.categorization !== undefined && object.categorization !== null) {
      message.categorization = object.categorization;
    }
    if (object.schema !== undefined && object.schema !== null) {
      message.schema = Schema.fromPartial(object.schema);
    }
    return message;
  },
};

messageTypeRegistry.set(SetSchemaRequest.$type, SetSchemaRequest);

const baseSetSchemaResponse: object = {
  $type: "deeplay.category_store.v2.SetSchemaResponse",
};

export const SetSchemaResponse = {
  $type: "deeplay.category_store.v2.SetSchemaResponse" as const,

  encode(
    _: SetSchemaResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSchemaResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSetSchemaResponse } as SetSchemaResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<SetSchemaResponse>): SetSchemaResponse {
    const message = { ...baseSetSchemaResponse } as SetSchemaResponse;
    return message;
  },
};

messageTypeRegistry.set(SetSchemaResponse.$type, SetSchemaResponse);

const baseGetSchemasRequest: object = {
  $type: "deeplay.category_store.v2.GetSchemasRequest",
};

export const GetSchemasRequest = {
  $type: "deeplay.category_store.v2.GetSchemasRequest" as const,

  encode(
    _: GetSchemasRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSchemasRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetSchemasRequest } as GetSchemasRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<GetSchemasRequest>): GetSchemasRequest {
    const message = { ...baseGetSchemasRequest } as GetSchemasRequest;
    return message;
  },
};

messageTypeRegistry.set(GetSchemasRequest.$type, GetSchemasRequest);

const baseGetSchemasResponse: object = {
  $type: "deeplay.category_store.v2.GetSchemasResponse",
};

export const GetSchemasResponse = {
  $type: "deeplay.category_store.v2.GetSchemasResponse" as const,

  encode(
    message: GetSchemasResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.entries) {
      GetSchemasResponse_SchemaEntry.encode(
        v!,
        writer.uint32(10).fork()
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSchemasResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetSchemasResponse } as GetSchemasResponse;
    message.entries = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.entries.push(
            GetSchemasResponse_SchemaEntry.decode(reader, reader.uint32())
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<GetSchemasResponse>): GetSchemasResponse {
    const message = { ...baseGetSchemasResponse } as GetSchemasResponse;
    message.entries = [];
    if (object.entries !== undefined && object.entries !== null) {
      for (const e of object.entries) {
        message.entries.push(GetSchemasResponse_SchemaEntry.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(GetSchemasResponse.$type, GetSchemasResponse);

const baseGetSchemasResponse_SchemaEntry: object = {
  $type: "deeplay.category_store.v2.GetSchemasResponse.SchemaEntry",
  categorization: "",
};

export const GetSchemasResponse_SchemaEntry = {
  $type: "deeplay.category_store.v2.GetSchemasResponse.SchemaEntry" as const,

  encode(
    message: GetSchemasResponse_SchemaEntry,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.categorization !== "") {
      writer.uint32(10).string(message.categorization);
    }
    if (message.schema !== undefined) {
      Schema.encode(message.schema, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): GetSchemasResponse_SchemaEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseGetSchemasResponse_SchemaEntry,
    } as GetSchemasResponse_SchemaEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categorization = reader.string();
          break;
        case 2:
          message.schema = Schema.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(
    object: DeepPartial<GetSchemasResponse_SchemaEntry>
  ): GetSchemasResponse_SchemaEntry {
    const message = {
      ...baseGetSchemasResponse_SchemaEntry,
    } as GetSchemasResponse_SchemaEntry;
    if (object.categorization !== undefined && object.categorization !== null) {
      message.categorization = object.categorization;
    }
    if (object.schema !== undefined && object.schema !== null) {
      message.schema = Schema.fromPartial(object.schema);
    }
    return message;
  },
};

messageTypeRegistry.set(
  GetSchemasResponse_SchemaEntry.$type,
  GetSchemasResponse_SchemaEntry
);

const baseDeleteSchemaRequest: object = {
  $type: "deeplay.category_store.v2.DeleteSchemaRequest",
  categorization: "",
};

export const DeleteSchemaRequest = {
  $type: "deeplay.category_store.v2.DeleteSchemaRequest" as const,

  encode(
    message: DeleteSchemaRequest,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.categorization !== "") {
      writer.uint32(10).string(message.categorization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteSchemaRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDeleteSchemaRequest } as DeleteSchemaRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.categorization = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(object: DeepPartial<DeleteSchemaRequest>): DeleteSchemaRequest {
    const message = { ...baseDeleteSchemaRequest } as DeleteSchemaRequest;
    if (object.categorization !== undefined && object.categorization !== null) {
      message.categorization = object.categorization;
    }
    return message;
  },
};

messageTypeRegistry.set(DeleteSchemaRequest.$type, DeleteSchemaRequest);

const baseDeleteSchemaResponse: object = {
  $type: "deeplay.category_store.v2.DeleteSchemaResponse",
};

export const DeleteSchemaResponse = {
  $type: "deeplay.category_store.v2.DeleteSchemaResponse" as const,

  encode(
    _: DeleteSchemaResponse,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): DeleteSchemaResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDeleteSchemaResponse } as DeleteSchemaResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromPartial(_: DeepPartial<DeleteSchemaResponse>): DeleteSchemaResponse {
    const message = { ...baseDeleteSchemaResponse } as DeleteSchemaResponse;
    return message;
  },
};

messageTypeRegistry.set(DeleteSchemaResponse.$type, DeleteSchemaResponse);

/**
 * Все запросы должны быть подписаны токеном, т.е. иметь хедер
 *
 *   Authorization: Bearer <token>
 */
export const CategoryStoreDefinition = {
  name: "CategoryStore",
  fullName: "deeplay.category_store.v2.CategoryStore",
  methods: {
    /**
     * Возвращает записи категоризации в точках, соответствующих набору
     * селекторов.
     *
     * Набор селекторов должен содержать селектор для каждого explicit атрибута
     * (т.е. атрибута, не помеченного как implicit в схеме). Для implicit
     * атрибутов селекторы можно не указывать: в этом случае они подставляются на
     * основе правил.
     *
     * Бросает ошибку с кодом `FAILED_PRECONDITION` при неверном имени
     * категоризации, либо неверном наборе селекторов.
     */
    get: {
      name: "Get",
      requestType: GetRequest,
      requestStream: false,
      responseType: GetResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /**
     * Возвращает подмножество записей категоризации, определяемое набором
     * селекторов.
     *
     * Набор селекторов должен содержать по селектору для каждого атрибута. Пустой
     * массив `value_in` в селекторе означает *, т.е. будут возвращены записи с
     * любым значением этого атрибута.
     *
     * Это медленная операция.
     *
     * Бросает ошибку с кодом `FAILED_PRECONDITION` при неверном имени
     * категоризации, неверном наборе селекторов, либо неверном наборе атрибутов в
     * `after`.
     */
    list: {
      name: "List",
      requestType: ListRequest,
      requestStream: false,
      responseType: ListResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /**
     * Создаёт, изменяет или удаляет записи категоризации.
     *
     * Для каждой записи должен быть определён полный набор атрибутов, объявленных
     * в схеме.
     *
     * Бросает ошибку с кодом `FAILED_PRECONDITION` при неверном имени
     * категоризации, неверном наборе атрибутов в записи, либо невалидном значении
     * атрибута или результате в записи.
     */
    set: {
      name: "Set",
      requestType: SetRequest,
      requestStream: false,
      responseType: SetResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /**
     * Атомарно очищает подмножество записей категоризации, определяемое набором
     * селекторов, и затем пишет новые записи.
     *
     * Набор селекторов должен содержать по селектору для каждого атрибута. Пустой
     * массив `value_in` в селекторе означает *, т.е. будут очищены записи с любым
     * значением этого атрибута.
     *
     * Для каждой новой записи должен быть определён полный набор атрибутов,
     * объявленных в схеме.
     *
     * Это медленная операция.
     *
     * Бросает ошибку с кодом `FAILED_PRECONDITION` при неверном имени
     * категоризации, неверном наборе селекторов, неверном наборе атрибутов в
     * записи, либо невалидном значении атрибута или результата в записи.
     */
    replace: {
      name: "Replace",
      requestType: ReplaceRequest,
      requestStream: false,
      responseType: ReplaceResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /**
     * Изменяет набор правил для категоризации.
     *
     * Правило состоит из набора селекторов в левой части и набора атрибутов в
     * правой части.
     *
     * В правой части могут быть только implicit атрибуты.
     *
     * Бросает ошибку с кодом `FAILED_PRECONDITION` при неверном имени
     * категоризации, неверном наборе селекторов или неверном наборе атрибутов в
     * правилах.
     */
    setRules: {
      name: "SetRules",
      requestType: SetRulesRequest,
      requestStream: false,
      responseType: SetRulesResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /**
     * Возвращает список правил для категоризации.
     *
     * Опционально принимает набор атрибутов, возвращая только правила,
     * действующие на нём.
     *
     * Бросает ошибку с кодом `FAILED_PRECONDITION` при неверном имени
     * категоризации или неверном наборе атрибутов.
     */
    getRules: {
      name: "GetRules",
      requestType: GetRulesRequest,
      requestStream: false,
      responseType: GetRulesResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /**
     * Создаёт или изменяет схему.
     *
     * Бросает ошибку с кодом `FAILED_PRECONDITION` при невалидной схеме.
     */
    setSchema: {
      name: "SetSchema",
      requestType: SetSchemaRequest,
      requestStream: false,
      responseType: SetSchemaResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /** Возвращает список схем. */
    getSchemas: {
      name: "GetSchemas",
      requestType: GetSchemasRequest,
      requestStream: false,
      responseType: GetSchemasResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
    /** Удаляет схему. Все данные категоризации будут удалены. */
    deleteSchema: {
      name: "DeleteSchema",
      requestType: DeleteSchemaRequest,
      requestStream: false,
      responseType: DeleteSchemaResponse,
      responseStream: false,
      options: {
        idempotencyLevel: "IDEMPOTENT",
      },
    },
  },
} as const;

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string }
  ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & {
      $case: T["$case"];
    }
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
